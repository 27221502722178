import { defineComponent } from 'vue';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import PTableValueEvolution from '@/presenters/PTableValueEvolution/PTableValueEvolution.vue';
import PTableVerticalValueEvolution from '@/presenters/PTableVerticalValueEvolution/PTableVerticalValueEvolution.vue';
import PDistributorsModal from '@/presenters/PDistributorsModal/PDistributorsModal.vue';
import PProductSheetScore from '@/presenters/PProductSheetScore/PProductSheetScore.vue';
import PCheckPhoto from '@/presenters/PCheckPhoto/PCheckPhoto.vue';
export default defineComponent({
    name: 'PThreeLvlTable',
    components: {
        AgGridVue: AgGridVue,
        pTableValueEvolution: PTableValueEvolution,
        pDistributorsModal: PDistributorsModal,
        pProductSheetScore: PProductSheetScore,
        pCheckPhoto: PCheckPhoto,
        pTableVerticalValueEvolution: PTableVerticalValueEvolution,
    },
    props: {
        rowData: { type: Array, required: true },
        agGridConfig: { type: Object, required: true },
        rowHeight: { type: Number, default: 40 },
    },
    setup: function (_props, _a) {
        var emit = _a.emit;
        function onFirstDataRendered(params) {
            var rowCount = params.api.getDisplayedRowCount();
            for (var i = 0; i <= 1 && i < rowCount; i++) {
                params.api.getDisplayedRowAtIndex(i).setExpanded(true);
            }
            emit('gridReady');
        }
        return {
            onFirstDataRendered: onFirstDataRendered,
        };
    },
});
