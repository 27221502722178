import { render, staticRenderFns } from "./CFarmersPieCharts.vue?vue&type=template&id=d6695ad0"
import script from "./CFarmersPieCharts.vue?vue&type=script&lang=ts"
export * from "./CFarmersPieCharts.vue?vue&type=script&lang=ts"
import style0 from "./CFarmersPieCharts.vue?vue&type=style&index=0&id=d6695ad0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports