export var SUPPLIER_SCOPES = [
    'supplierData:read:supplier',
    'supplier-data-supporter:read:supplier',
    'openid',
    'profile',
    'analytics:write:members',
];
export var OPTIONNAL_SUPPLIER_SCOPES = ['farmer-data:read:supplier', 'raw-sales:read:supplier'];
export var PREMIUM_SUPPLIER_SCOPES = ['supplier-data-premium:read:supplier'];
export var PLATINIUM_SUPPLIER_SCOPES = ['supplier-data-platinium:read:supplier'];
