import axios from 'axios'

const LOCAL_STORAGE_ACCESS_TOKEN_KEY = 'access_token'

class OfferApiService {
  constructor({ productApiHost }) {
    this.productApiHost = productApiHost
  }

  get api() {
    return axios.create({
      baseURL: `${this.productApiHost}/v1`,
      timeout: 20000,
      headers: {
        authorization: `Bearer ${localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)}`,
      },
    })
  }

  async getCooperatives() {
    const response = await this.api.get('/cooperatives')
    return response.data
  }
}

export default OfferApiService
