<template>
  <div>
    <highcharts class="chart" :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { Chart } from 'highcharts-vue'

export default defineComponent({
  components: {
    Highcharts: Chart,
  },
  props: {
    seriesData: {
      type: Array,
      default() {
        return []
      },
    },
    categories: {
      type: Array,
      default() {
        return []
      },
    },
    showLegend: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  setup({ seriesData, categories, showLegend }) {
    function labelFormatter() {
      return `<div class="chart__legend__item">
                  <div class="chart__legend__item__icon" style="background-color:${this.userOptions.color};">
                    <span class="adn-icon adn-icon--${this.userOptions.symbol}"></span>
                  </div>
                  <span class="chart__legend__item__title">${this.name}</span>
                </div>`
    }

    const legendOptions = showLegend
      ? {
          className: 'chart__legend',
          enabled: true,
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'top',
          symbolHeight: 0.001,
          symbolWidth: 0.001,
          symbolRadius: 0.001,
          width: '20%',
          floating: false,
          backgroundColor: '#FFFFFF',
          useHTML: true,
          labelFormatter,
        }
      : {
          enabled: false,
        }

    const chartOptions = ref({
      chart: {
        type: 'column',
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      xAxis: {
        categories,
      },
      yAxis: {
        title: {
          text: null,
        },
      },
      exporting: {
        enabled: false,
      },
      legend: legendOptions,
      tooltip: {
        padding: 16,
        borderRadius: 8,
        borderWidth: 0,
        useHTML: true,
        backgroundColor: 'white',
        className: 'chart__tooltip',
        headerFormat: '<div class="chart__tooltip__title">{point.x}</div>',
        pointFormat: `
            <div class="chart__tooltip__value">
              {series.name}: <span>{point.y} €</span>
            </div>
            <div class="chart__tooltip__value">
              Total: <span>{point.stackTotal} €</span>
            </div>
          `,
      },
      plotOptions: {
        column: {
          groupPadding: 0.05,
          stacking: 'normal',
        },
      },
      series: seriesData,
    })

    return {
      chartOptions,
    }
  },
})
</script>

<style lang="scss">
.chart {
  &__tooltip {
    font-family: Heebo, Helvetica, Arial, sans-serif;
    &__title {
      font-weight: bold;
      font-size: $font-size-xs;
      margin-bottom: $spacing-1;
    }
    &__value {
      margin-bottom: $spacing-1;
      color: $color-text-light;
      span {
        font-weight: bold;
      }
    }
  }
  &__legend {
    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: var(--idf-spacing-2);
      margin-bottom: var(--idf-spacing-3);

      &__title {
        font-family: Heebo, Helvetica, Arial, sans-serif;
        font-weight: 100;
        font-size: var(--idf-line-height-xs) !important;
      }

      &__icon {
        border-radius: 1.2rem;
        width: 2.4rem;
        height: 2.4rem;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        color: var(--idf-color-neutral-100);

        &__inactive {
          background-color: #cccccc;
        }

        span {
          font-size: var(--idf-line-height-xs) !important;
          color: #fff;
          vertical-align: middle;
        }
      }
    }
  }

  .highcharts-legend-item-hidden {
    .chart__legend__item__icon {
      background-color: #cccccc !important;
    }
  }
}
</style>
