<template>
  <div class="pcard">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PCard',
}
</script>

<style lang="scss">
@import '~@b2ag/design-system/dist/aladin.scss';
.pcard {
  margin-top: 19px;
  padding: 30px 20px;
  background-color: white;
  border-radius: 8px;
}
</style>
