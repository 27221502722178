var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('h2',{staticClass:"c-funnel__title bold"},[_vm._v("Parcours de conversion")]),_c('p-load-spinner',{attrs:{"isDisplayed":!_vm.isLoaded}}),(_vm.isLoaded && _vm.funnelInfos)?_c('div',{staticClass:"c-funnel"},[_c('div',{staticClass:"c-funnel__summary"},[_c('div',{staticClass:"c-funnel__summary__card"},[_c('idf-simple-info-evol-card',{attrs:{"info":`${_vm.farmerFunnelSummary.conversion_rate.value || '-'} %`,"title":"Taux de conversion*","evol":{
            value: _vm.farmerFunnelSummary.conversion_rate.evolution_ratio || '-',
            unit: _vm.farmerFunnelSummary.conversion_rate.evolution_unit,
          }}}),_c('span',{staticClass:"c-funnel__tooltip"},[_vm._v("Nombre de mes produits achetés sur la somme de mes produits consultés.")])],1),_c('div',{staticClass:"c-funnel__summary__card"},[_c('idf-simple-info-evol-card',{attrs:{"info":`${_vm.farmerFunnelSummary.sessions_by_farmer.value || '-'}`,"title":"Sessions par exploitations*","evol":{
            value: _vm.farmerFunnelSummary.sessions_by_farmer.evolution_ratio || '-',
            unit: _vm.farmerFunnelSummary.sessions_by_farmer.evolution_unit,
          }}}),_c('span',{staticClass:"c-funnel__tooltip"},[_vm._v("Nombre moyen de visite par exploitation agricole.")])],1),_c('div',{staticClass:"c-funnel__summary__card"},[_c('idf-simple-info-evol-card',{attrs:{"info":`${_vm.formatNumber(_vm.farmerFunnelSummary.nb_of_sessions.value) || '-'}`,"title":"Nombre de sessions*","evol":{
            value: _vm.farmerFunnelSummary.nb_of_sessions.evolution_ratio || '-',
            unit: _vm.farmerFunnelSummary.nb_of_sessions.evolution_unit,
          }}}),_c('span',{staticClass:"c-funnel__tooltip"},[_vm._v("Nombre de visites total.")])],1),_c('div',{staticClass:"c-funnel__summary__card"},[_c('idf-simple-info-evol-card',{attrs:{"info":`${_vm.formatNumber(_vm.farmerFunnelSummary.nb_of_product_views.value) || '-'}`,"title":"Nombre de vues*","evol":{
            value: _vm.farmerFunnelSummary.nb_of_product_views.evolution_ratio || '-',
            unit: _vm.farmerFunnelSummary.nb_of_product_views.evolution_unit,
          }}}),_c('span',{staticClass:"c-funnel__tooltip"},[_vm._v("Nombre de fois qu’une de mes fiches produits a été consultée sur la période sélectionnée.")])],1),_c('div',{staticClass:"c-funnel__summary__card"},[_c('idf-simple-info-evol-card',{attrs:{"info":`${_vm.farmerFunnelSummary.product_views_by_session.value || '-'}`,"title":"Produits vus par sessions*","evol":{
            value: _vm.farmerFunnelSummary.product_views_by_session.evolution_ratio || '-',
            unit: _vm.farmerFunnelSummary.product_views_by_session.evolution_unit,
          }}}),_c('span',{staticClass:"c-funnel__tooltip"},[_vm._v("Nombre moyen de fiches produits vues lors d’une visite.")])],1),_c('div',{staticClass:"c-funnel__summary__card"},[_c('idf-simple-info-evol-card',{attrs:{"info":`${_vm.formatTimeBySession(_vm.farmerFunnelSummary.time_by_session.value)} min`,"title":"Temps par session*","evol":{
            value: _vm.farmerFunnelSummary.time_by_session.evolution_ratio || '-',
            unit: _vm.farmerFunnelSummary.time_by_session.evolution_unit,
          }}}),_c('span',{staticClass:"c-funnel__tooltip"},[_vm._v("Temps moyen par visite (en minutes).")])],1)]),_vm._m(0),_c('div',{staticClass:"c-funnel__container"},_vm._l((_vm.funnelInfos),function(info,idx){return _c('div',{key:idx,staticClass:"c-funnel__container__column"},[_c('div',{staticClass:"c-funnel__container__column__steps"},[_c('div',{staticClass:"c-funnel__steps--title"},[_vm._v("Etape "+_vm._s(idx + 1))]),_c('div',{staticClass:"c-funnel__steps--name bold"},[_vm._v(_vm._s(info.name))])]),_c('div',{staticClass:"c-funnel__container__column__sessions"},[_c('div',{staticClass:"c-funnel__container__column__sessions--value"},[_c('div',[_vm._v("Sessions avec "+_vm._s(info.name))]),_c('div',{staticClass:"bold"},[_vm._v(" "+_vm._s(_vm.formatNumber(info.value))+" ")])]),_c('div',{staticClass:"c-funnel__container__column__sessions--graph"},[_c('svg',{attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","viewBox":"0 0 100 100","height":"233","width":"100%","preserveAspectRatio":"none meet"}},[_c('path',{attrs:{"d":_vm.getPath(idx),"fill":"#B3C5FC"}})])])]),_c('div',{staticClass:"c-funnel__container__column__drop",style:({ visibility: info.drop !== null ? 'visible' : 'hidden' })},[_c('img',{attrs:{"src":require("@/assets/icon-drop.svg")}}),_c('div',{staticClass:"c-funnel__container__column__drop--title"},[_vm._v("Abandon")]),_c('div',[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(info.drop && info.drop.evolution_ratio)+_vm._s(info.drop && info.drop.evolution_unit))]),_vm._v(" ("+_vm._s(info.drop && info.drop.value)+") ")]),_c('div',{staticClass:"c-funnel__container__column__drop--aladin bold"},[_c('img',{attrs:{"src":require("@/assets/icon-drop-aladin.svg")}}),_c('span',[_vm._v(_vm._s(info.global_drop && info.global_drop.evolution_ratio)+_vm._s(info.global_drop && info.global_drop.evolution_unit))])])])])}),0)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"c-funnel__container__title bold"},[_c('h4',{staticClass:"c-funnel__container__title__note"},[_vm._v("Note explicative: "),_c('br')]),_c('h4',{staticClass:"c-funnel__container__title__message"},[_vm._v(" Les produits achetés issus des suggestions de commandes poussées par les TC et validées par les agriculteurs ne transitent pas par le panier."),_c('br'),_vm._v(" Ainsi, le nombre de produits achetés ou sessions avec achat peut être supérieur au nombre de produits ajoutés au panier ou sessions avec produits ajoutés au panier. ")])])
}]

export { render, staticRenderFns }