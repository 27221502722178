import { ScopesService } from './scopes.service';
var scopesService;
export function provideScopesService(scopes) {
    scopesService = new ScopesService(scopes);
}
export function useScopesService() {
    if (!scopesService) {
        throw new Error('ScopesService was not initialized');
    }
    return scopesService;
}
