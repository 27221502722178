import { provide, inject } from 'vue';
// eslint-disable-next-line symbol-description
export var storeSymbol = Symbol('store');
export function provideStore(store) {
    provide(storeSymbol, store);
}
export function useStore() {
    var store = inject(storeSymbol);
    if (!store)
        throw new Error('Store was not provided');
    return store;
}
