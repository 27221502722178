<template>
  <h2 class="view-title">
    <slot></slot>
  </h2>
</template>

<script>
export default {
  name: 'PViewTitle',
}
</script>

<style lang="scss"></style>
