/* eslint-disable import/prefer-default-export */
import { InvalidScopesError } from './invalid-scope-error';
var ScopesService = /** @class */ (function () {
    function ScopesService(requiredScopes) {
        this.requiredScopes = requiredScopes;
    }
    ScopesService.prototype.validateScopes = function (currentScopes) {
        if (this.requiredScopes.every(function (scope) { return currentScopes.includes(scope); }))
            return true;
        throw new InvalidScopesError();
    };
    return ScopesService;
}());
export { ScopesService };
