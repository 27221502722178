var categorySymbolMap = new Map()
    .set("aménagement de l'exploitation", ['category-silo', '#273d59'])
    .set("equipement de l'exploitation", ['category-silo', '#273d59'])
    .set('equipement culture', ['category-circle', '#347252'])
    .set("equipement de l'exploitant", ['category-equipment', '#a69992'])
    .set("equipements de l'exploitant", ['category-equipment', '#a69992'])
    .set('nutrition du végétal et amendements', ['category-fertilizer', '#5bb3f0'])
    .set('santé du végétal', ['category-health-plant', '#7dc12f'])
    .set('semences', ['category-seeds', '#fec200'])
    .set('services', ['category-services', '#0511f2'])
    .set('equipement vigne-arbo', ['category-wineyard', '']);
var getSymbol = function (category) {
    var tuple = categorySymbolMap.get(category.toLowerCase());
    return tuple ? tuple[0] : '';
};
var getColor = function (category) {
    var tuple = categorySymbolMap.get(category.toLowerCase());
    return tuple ? tuple[1] : '';
};
export { getSymbol, getColor };
