var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s)
                if (Object.prototype.hasOwnProperty.call(s, p))
                    t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var TrackingEventService = /** @class */ (function () {
    // eslint-disable-next-line no-empty-function, no-useless-constructor
    function TrackingEventService(gtmPlugin) {
        this.gtmPlugin = gtmPlugin;
    }
    TrackingEventService.prototype.trackEvent = function (name, eventContext) {
        if (eventContext === void 0) {
            eventContext = {};
        }
        var dataLayer = this.gtmPlugin.dataLayer() ? this.gtmPlugin.dataLayer() : [];
        dataLayer.push(__assign({}, eventContext));
        this.gtmPlugin.trackEvent({ event: name });
    };
    TrackingEventService.prototype.removeFromDataLayer = function (name) {
        var _a;
        var dataLayer = this.gtmPlugin.dataLayer() ? this.gtmPlugin.dataLayer() : [];
        dataLayer.push((_a = {}, _a[name] = undefined, _a));
    };
    return TrackingEventService;
}());
export default TrackingEventService;
