<template>
  <div class="v-sales">
    <h1 class="bold">Ventes par région <sup>Beta platinium</sup></h1>
    <div class="v-sales__datepicker">
      <c-date-picker />
    </div>
    <div class="v-sales__filters">
      <c-filter />
    </div>
    <p-card>
      <c-sales-summary />
    </p-card>
    <p-card v-if="checkFeaturesAccess('salesByRegion')">
      <c-sales-by-region />
    </p-card>
    <p-card v-if="checkFeaturesAccess('salesByKindByRegion')">
      <c-sales-by-kind-by-region />
    </p-card>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useStore } from '@/store/useStore'
import CDatePicker from '@/components/CDatepicker/CDatePicker.vue'
import CFilter from '@/components/CFilter/CFilter.vue'
import CSalesByRegion from '@/components/CSalesByRegion/CSalesByRegion.vue'
import CSalesByKindByRegion from '@/components/CSalesByKindByRegion/CSalesByKindByRegion.vue'
import CSalesSummary from '@/components/CSalesSummary/CSalesSummary.vue'
import PCard from '@/presenters/PCard/PCard.vue'

export default defineComponent({
  name: 'VSalesByRegion',
  components: {
    CDatePicker,
    CFilter,
    CSalesSummary,
    CSalesByRegion,
    CSalesByKindByRegion,
    PCard,
  },

  setup() {
    const store = useStore()
    const features = computed(() => store.getters.user.features)

    function checkFeaturesAccess(featureName) {
      return features.value.includes(featureName)
    }

    return {
      checkFeaturesAccess,
    }
  },
})
</script>
