<template>
  <div>
    <span @click="showDistributors()" class="number-distributors">{{ distributors.length }}</span>
    <adn-modal title="Distributeurs" ref="distributors-modal" message="message">
      <template slot="body">
        <ul v-if="distributors.length !== 0">
          <li v-for="distributor in distributors" :key="distributor">
            <span class="sellers-modal__seller-name">{{ distributor }}</span>
          </li>
        </ul>
        <span v-else>Aucun distributeur ne propose d'offre sur ce produit actuellement</span>
      </template>
    </adn-modal>
  </div>
</template>

<script>
import AdnModal from '@b2ag/design-system/dist/components/AdnModal/AdnModal.vue'

export default {
  name: 'PDistributorsModal',
  components: {
    AdnModal,
  },
  data() {
    return {
      distributors: [],
    }
  },
  beforeMount() {
    this.distributors = this.params.value
  },
  methods: {
    showDistributors() {
      this.$refs['distributors-modal'].show()
    },
  },
}
</script>

<style lang="scss" scoped>
.number-distributors {
  cursor: pointer;
  color: $color-main;
  &:hover {
    text-decoration: underline;
  }
}
</style>
