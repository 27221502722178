var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b)
                if (Object.prototype.hasOwnProperty.call(b, p))
                    d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable import/prefer-default-export */
import { INVALID_SCOPE } from './views/VAuthCallback.constants';
var InvalidScopesError = /** @class */ (function (_super) {
    __extends(InvalidScopesError, _super);
    function InvalidScopesError() {
        return _super.call(this, INVALID_SCOPE) || this;
    }
    return InvalidScopesError;
}(Error));
export { InvalidScopesError };
