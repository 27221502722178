<template>
  <div class="v-sales">
    <h1 class="bold">Ventes globales</h1>
    <div class="v-sales__datepicker">
      <c-date-picker />
    </div>
    <div class="v-sales__filters">
      <c-filter />
    </div>
    <p-card>
      <c-sales-summary />
    </p-card>
    <p-card>
      <c-kind-rankings />
    </p-card>
    <p-card>
      <c-order-count-by-subkind-chart />
    </p-card>
    <p-card v-if="checkFeaturesAccess('topSubkinds')">
      <c-top-subkinds />
    </p-card>
    <p-card v-if="checkFeaturesAccess('topProducts')">
      <c-top-products />
    </p-card>
    <p-card v-if="checkFeaturesAccess('salesByRegion')">
      <c-sales-by-region />
    </p-card>
    <p-card class="v-sales__components">
      <c-sales-table />
    </p-card>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store/useStore'
import CDatePicker from '@/components/CDatepicker/CDatePicker.vue'
import PCard from '@/presenters/PCard/PCard.vue'
import CSalesTable from '@/components/CSalesTable/CSalesTable.vue'
import CFilter from '@/components/CFilter/CFilter.vue'
import CSalesSummary from '@/components/CSalesSummary/CSalesSummary.vue'
import CKindRankings from '@/components/CKindRankings/CKindRankings.vue'
import COrderCountBySubkindChart from '@/components/COrderCountBySubkindChart/COrderCountBySubkindChart.vue'
import CTopProducts from '@/components/CTopProducts/CTopProducts.vue'
import CTopSubkinds from '@/components/CTopSubkinds/CTopSubkinds.vue'
import CSalesByRegion from '@/components/CSalesByRegion/CSalesByRegion.vue'

export default defineComponent({
  name: 'VSales',
  components: {
    CDatePicker,
    CFilter,
    CKindRankings,
    COrderCountBySubkindChart,
    CSalesByRegion,
    CSalesTable,
    CSalesSummary,
    CTopProducts,
    CTopSubkinds,
    PCard,
  },
  setup() {
    const store = useStore()
    const features = computed(() => store.getters.user.features)

    function checkFeaturesAccess(featureName) {
      return features.value.includes(featureName)
    }

    return {
      checkFeaturesAccess,
    }
  },
})
</script>
