<template>
  <div class="product-sheet-score" @click="showModal()">
    <span class="adn-icon adn-icon--category-favorite" :class="{ 'product-sheet-score__active-star': hasImage }"></span>
    <span class="adn-icon adn-icon--category-favorite" :class="{ 'product-sheet-score__active-star': hasDescription }"></span>
    <span class="adn-icon adn-icon--category-favorite" :class="{ 'product-sheet-score__active-star': hasProductSheet }"></span>

    <adn-modal title="Score produit" ref="score-modal" message="message">
      <template slot="body">
        Pour avoir la note maximale, il faut que le produit ait une image, une description et un lien vers une fiche produit.
        <div class="product-sheet-score--miss" v-if="!hasImage || !hasDescription || !hasProductSheet">Il manque au produit:</div>
        <ul>
          <li class="product-sheet-score--li" v-if="!hasImage">- une image</li>
          <li class="product-sheet-score--li" v-if="!hasDescription">- une description</li>
          <li class="product-sheet-score--li" v-if="!hasProductSheet">- une fiche produit</li>
        </ul>
      </template>
    </adn-modal>
  </div>
</template>

<script>
import AdnModal from '@b2ag/design-system/dist/components/AdnModal/AdnModal.vue'

export default {
  name: 'PProductSheetScore',
  components: {
    AdnModal,
  },
  data() {
    return {
      hasImage: false,
      hasDescription: false,
      hasProductSheet: false,
    }
  },
  beforeMount() {
    this.hasImage = this.params && this.params.value ? this.params.value.hasImage : 0
    this.hasDescription = this.params && this.params.value ? this.params.value.hasDescription : 0
    this.hasProductSheet = this.params && this.params.value ? this.params.value.hasProductSheet : 0
  },
  methods: {
    showModal() {
      this.$refs['score-modal'].show()
    },
  },
}
</script>
<style lang="scss">
@import '~@b2ag/design-system/dist/aladin.scss';
@import '../../lib/_variables.scss';
.product-sheet-score__active-star {
  color: $color-warning;
}
.product-sheet-score {
  position: absolute;
  color: $color-inactive-light;
  height: 95%;
  right: 0;
  cursor: pointer;

  &:hover {
    border-bottom: 0.1rem black solid;
  }

  &--miss {
    margin-top: var(--idf-spacing-2);
  }

  &--li {
    margin-left: var(--idf-spacing-3);
  }
}
</style>
