import { defineComponent, ref } from 'vue';
import PVerticalSlider from '@/presenters/PVerticalSlider/PVerticalSlider.vue';
import PVerticalSliderMenuItem from '@/presenters/PVerticalSliderMenuItem/PVerticalSliderMenuItem.vue';
export default defineComponent({
    name: 'CVerticalSliderNavigation',
    components: {
        PVerticalSlider: PVerticalSlider,
        PVerticalSliderMenuItem: PVerticalSliderMenuItem,
    },
    props: {
        menuObject: {
            type: Array,
            required: true,
        },
        activeId: {
            type: String,
            default: '',
        },
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var isOpened = ref(false);
        function onSliderOpen() {
            isOpened.value = true;
            emit('sliderOpen');
        }
        function onSliderClose() {
            isOpened.value = false;
            emit('sliderClose');
        }
        function isActive(item) {
            if (item.children) {
                return item.children.some(function (c) { return c.id === props.activeId; }) || item.id === props.activeId;
            }
            return item.id === props.activeId;
        }
        function onItemClicked(id) {
            emit('clicked', id);
        }
        return {
            isActive: isActive,
            onSliderOpen: onSliderOpen,
            onSliderClose: onSliderClose,
            onItemClicked: onItemClicked,
            isOpened: isOpened,
        };
    },
});
