var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s)
                if (Object.prototype.hasOwnProperty.call(s, p))
                    t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m)
        return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done)
            ar.push(r.value);
    }
    catch (error) {
        e = { error: error };
    }
    finally {
        try {
            if (r && !r.done && (m = i["return"]))
                m.call(i);
        }
        finally {
            if (e)
                throw e.error;
        }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2)
        for (var i = 0, l = from.length, ar; i < l; i++) {
            if (ar || !(i in from)) {
                if (!ar)
                    ar = Array.prototype.slice.call(from, 0, i);
                ar[i] = from[i];
            }
        }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Vue from 'vue';
import Vuex from 'vuex';
import { getSymbol } from './category-symbol-color.utils';
import { reportingApiService } from '@/services';
import COLORS from '@/lib/colors';
Vue.use(Vuex);
export var mutations = {
    SET_SALES_SUMMARY: function (state, value) {
        state.salesSummary = value;
    },
    SET_KIND_RANKINGS: function (state, value) {
        state.kindRankings = value;
    },
    SET_ORDER_BY_SUBKIND_GRAPH_DATA: function (state, value) {
        state.orderCountBySubkindChartData = value;
    },
    SET_TOP_PRODUCTS: function (state, value) {
        state.topProducts = value;
    },
    SET_TOP_SUBKINDS: function (state, value) {
        state.topSubkinds = value;
    },
    SET_SALES_BY_REGION: function (state, value) {
        state.salesByRegion = value;
    },
    SET_SALES_BY_PARTNER_V3: function (state, value) {
        state.salesByPartnerV3 = value;
    },
    SET_SALES_BY_KIND_BY_REGION_V3: function (state, value) {
        state.salesByKindByRegionV3 = value;
    },
    SET_SALES_BY_KIND_BEHAVIOR: function (state, value) {
        state.salesByKindBehavior = value;
    },
    SET_SALES_REVENUE_BY_KIND_BY_REVENUE: function (state, value) {
        state.salesRevenueByKindByMonth = value;
    },
};
export var getters = {
    salesSummary: function (state) {
        return state.salesSummary;
    },
    kindRankings: function (state) {
        return state.kindRankings;
    },
    orderCountBySubkindChartData: function (state) {
        return state.orderCountBySubkindChartData;
    },
    topProducts: function (state) {
        return state.topProducts;
    },
    topSubkinds: function (state) {
        return state.topSubkinds;
    },
    salesByRegion: function (state) {
        return state.salesByRegion;
    },
    salesByPartnerV3: function (state) {
        return state.salesByPartnerV3;
    },
    salesByKindByRegionV3: function (state) {
        return state.salesByKindByRegionV3;
    },
    salesByKindBehavior: function (state) {
        return state.salesByKindBehavior;
    },
    salesRevenueByKindByMonth: function (state) {
        return state.salesRevenueByKindByMonth;
    },
};
export var actions = {
    fetchSalesSummary: function (context) {
        return __awaiter(this, void 0, Promise, function () {
            var _a, isAuthenticated, user, dateRange, selectedFilters, summary;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = context.getters, isAuthenticated = _a.isAuthenticated, user = _a.user, dateRange = _a.dateRange, selectedFilters = _a.selectedFilters;
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getSalesSummary(user.id, dateRange.startDate.toLocaleDateString('fr-FR'), dateRange.endDate.toLocaleDateString('fr-FR'), selectedFilters)];
                    case 1:
                        summary = _b.sent();
                        context.commit('SET_SALES_SUMMARY', summary);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchKindRankings: function (context) {
        return __awaiter(this, void 0, Promise, function () {
            var _a, isAuthenticated, user, dateRange, selectedFilters, rankings;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = context.getters, isAuthenticated = _a.isAuthenticated, user = _a.user, dateRange = _a.dateRange, selectedFilters = _a.selectedFilters;
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getKindRankings(user.id, dateRange.startDate.toLocaleDateString('fr-FR'), dateRange.endDate.toLocaleDateString('fr-FR'), selectedFilters)];
                    case 1:
                        rankings = _b.sent();
                        context.commit('SET_KIND_RANKINGS', rankings);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchOrderCountBySubkindChartData: function (context) {
        return __awaiter(this, void 0, Promise, function () {
            var _a, isAuthenticated, user, dateRange, selectedFilters, data, colors_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = context.getters, isAuthenticated = _a.isAuthenticated, user = _a.user, dateRange = _a.dateRange, selectedFilters = _a.selectedFilters;
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getOrderCountBySubkindChartData(user.id, dateRange.startDate.toLocaleDateString('fr-FR'), dateRange.endDate.toLocaleDateString('fr-FR'), selectedFilters)];
                    case 1:
                        data = _b.sent();
                        colors_1 = __spreadArray([], __read(COLORS), false).sort(function () { return 0.5 - Math.random(); });
                        data.seriesData = data.seriesData.map(function (c, idx) {
                            var catWithColor = __assign({}, c);
                            catWithColor.color = colors_1[idx];
                            return catWithColor;
                        });
                        context.commit('SET_ORDER_BY_SUBKIND_GRAPH_DATA', data);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchTopProducts: function (context) {
        return __awaiter(this, void 0, Promise, function () {
            var _a, isAuthenticated, user, dateRange, selectedFilters, data;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = context.getters, isAuthenticated = _a.isAuthenticated, user = _a.user, dateRange = _a.dateRange, selectedFilters = _a.selectedFilters;
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getTopProducts(user.id, dateRange.startDate.toLocaleDateString('fr-FR'), dateRange.endDate.toLocaleDateString('fr-FR'), selectedFilters)];
                    case 1:
                        data = _b.sent();
                        context.commit('SET_TOP_PRODUCTS', data);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchTopSubkinds: function (context) {
        return __awaiter(this, void 0, Promise, function () {
            var _a, isAuthenticated, user, dateRange, selectedFilters, data;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = context.getters, isAuthenticated = _a.isAuthenticated, user = _a.user, dateRange = _a.dateRange, selectedFilters = _a.selectedFilters;
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getTopSubkinds(user.id, dateRange.startDate.toLocaleDateString('fr-FR'), dateRange.endDate.toLocaleDateString('fr-FR'), selectedFilters)];
                    case 1:
                        data = _b.sent();
                        context.commit('SET_TOP_SUBKINDS', data);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchSalesByRegion: function (context) {
        return __awaiter(this, void 0, Promise, function () {
            var _a, isAuthenticated, user, dateRange, selectedFilters, salesByRegion;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = context.getters, isAuthenticated = _a.isAuthenticated, user = _a.user, dateRange = _a.dateRange, selectedFilters = _a.selectedFilters;
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getSalesByRegion(user.id, dateRange.startDate.toLocaleDateString('fr-FR'), dateRange.endDate.toLocaleDateString('fr-FR'), selectedFilters)];
                    case 1:
                        salesByRegion = _b.sent();
                        context.commit('SET_SALES_BY_REGION', salesByRegion);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchSalesByPartnerV3: function (context) {
        return __awaiter(this, void 0, Promise, function () {
            var _a, isAuthenticated, user, dateRange, selectedFilters, salesByPartner;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = context.getters, isAuthenticated = _a.isAuthenticated, user = _a.user, dateRange = _a.dateRange, selectedFilters = _a.selectedFilters;
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getSalesByPartnerV3(user.id, dateRange.startDate.toLocaleDateString('fr-FR'), dateRange.endDate.toLocaleDateString('fr-FR'), selectedFilters)];
                    case 1:
                        salesByPartner = _b.sent();
                        context.commit('SET_SALES_BY_PARTNER_V3', salesByPartner);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchSalesByKindByRegionV3: function (context) {
        return __awaiter(this, void 0, Promise, function () {
            var _a, isAuthenticated, user, dateRange, selectedFilters, salesByKindByRegion;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = context.getters, isAuthenticated = _a.isAuthenticated, user = _a.user, dateRange = _a.dateRange, selectedFilters = _a.selectedFilters;
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getSalesByKindByRegionV3(user.id, dateRange.startDate.toLocaleDateString('fr-FR'), dateRange.endDate.toLocaleDateString('fr-FR'), selectedFilters)];
                    case 1:
                        salesByKindByRegion = _b.sent();
                        context.commit('SET_SALES_BY_KIND_BY_REGION_V3', salesByKindByRegion);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchSalesByKindBehavior: function (context) {
        return __awaiter(this, void 0, Promise, function () {
            var _a, isAuthenticated, user, dateRange, selectedFilters, salesByKindByBehavior;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = context.getters, isAuthenticated = _a.isAuthenticated, user = _a.user, dateRange = _a.dateRange, selectedFilters = _a.selectedFilters;
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getSalesByKindBehavior(user.id, dateRange.startDate.toLocaleDateString('fr-FR'), dateRange.endDate.toLocaleDateString('fr-FR'), selectedFilters)];
                    case 1:
                        salesByKindByBehavior = _b.sent();
                        context.commit('SET_SALES_BY_KIND_BEHAVIOR', salesByKindByBehavior);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchSalesRevenueByKindByMonth: function (context) {
        return __awaiter(this, void 0, Promise, function () {
            var _a, isAuthenticated, user, dateRange, selectedFilters, salesRevenueByKindByMonth, colors_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = context.getters, isAuthenticated = _a.isAuthenticated, user = _a.user, dateRange = _a.dateRange, selectedFilters = _a.selectedFilters;
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getSalesRevenueByKindByMonth(user.id, dateRange.startDate.toLocaleDateString('fr-FR'), dateRange.endDate.toLocaleDateString('fr-FR'), selectedFilters)];
                    case 1:
                        salesRevenueByKindByMonth = _b.sent();
                        colors_2 = __spreadArray([], __read(COLORS), false).sort(function () { return 0.5 - Math.random(); });
                        salesRevenueByKindByMonth.categories = salesRevenueByKindByMonth.categories.map(function (c, idx) {
                            var catWithColor = __assign({}, c);
                            catWithColor.color = colors_2[idx];
                            catWithColor.symbol = getSymbol(c.name);
                            return catWithColor;
                        });
                        context.commit('SET_SALES_REVENUE_BY_KIND_BY_REVENUE', salesRevenueByKindByMonth);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
};
var moduleSalesV3 = {
    state: {
        salesSummary: null,
        kindRankings: [],
        orderCountBySubkindChartData: null,
        topProducts: [],
        topSubkinds: [],
        salesByRegion: null,
        salesByPartnerV3: [],
        salesByKindByRegionV3: [],
        salesByKindBehavior: [],
        salesRevenueByKindByMonth: [],
    },
    actions: actions,
    mutations: mutations,
    getters: getters,
};
export default moduleSalesV3;
