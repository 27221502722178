<template>
  <div class="container">
    <c-last-update-title>
      Catalogue des produits de <b>{{ user.portal_name }}</b>
    </c-last-update-title>
    <p-card>
      <c-catalog-summary />
    </p-card>

    <c-product-catalog v-if="isDataLoaded" />
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store/useStore'
import PCard from '@/presenters/PCard/PCard.vue'
import CCatalogSummary from '@/components/CCatalogSummary/CCatalogSummary.vue'
import CProductCatalog from '@/components/CProductCatalog/CProductCatalog.vue'
import CLastUpdateTitle from '@/components/CLastUpdateTitle/CLastUpdateTitle.vue'

export default defineComponent({
  name: 'VCatalog',
  components: {
    CCatalogSummary,
    CProductCatalog,
    CLastUpdateTitle,
    PCard,
  },
  setup() {
    const store = useStore()

    const user = computed(() => store.getters.user)
    const isDataLoaded = computed(() => store.getters.isStoreInitialized)

    return {
      user,
      isDataLoaded,
    }
  },
})
</script>

<style lang="scss">
.catalog__list {
  font-size: 14px;
}
</style>
