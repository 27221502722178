<template>
  <div class="partner-card" :class="{ 'partner-card--active': isSeller }">
    <p-partner-logo class="partner-card__default-name-wrapper" :partnerInfo="partner.id"></p-partner-logo>
    <div v-if="isSeller" class="partner-card__products" data-test="partner-products">
      vend
      <span class="partner-card__products-count">{{ productsCount }}</span>
      de mes produits
    </div>
    <div v-else class="partner-card__products" data-test="partner-no-products">ne vend aucun de mes produits</div>
  </div>
</template>

<script>
import PPartnerLogo from '@/presenters/PPartnerLogo/PPartnerLogo.vue'

export default {
  name: 'PBuyersPartnerCard',
  props: {
    productsCount: {
      type: Number,
      default: 0,
    },
    partner: {
      type: Object,
    },
  },
  components: {
    PPartnerLogo,
  },
  computed: {
    isSeller() {
      return this.productsCount > 0
    },
  },
}
</script>

<style lang="scss">
@import '~@b2ag/design-system/dist/aladin.scss';
.partner-card {
  width: calc(100% / 8);
  margin-bottom: 15px;
  text-align: center;
  border: 1px solid $color-inactive-light;
  box-sizing: border-box;
  border-radius: 8px;
  color: $color-inactive-dark;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 150px;
  margin-right: 15px;
  &:nth-child(7n) {
    margin-right: 0;
  }
  &.partner-card--active {
    border: 1px solid $color-main-dark;
    color: $color-black;
  }
  img {
    width: 8rem;
  }
  &__default-name-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    & > div {
      font-size: 18px;
    }
  }
  &__products-count {
    font-weight: bold;
  }
  &__products {
    margin-top: auto;
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
