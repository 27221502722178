import { defineComponent } from 'vue';
import { useAuthService } from '@/services/auth';
export default defineComponent({
    setup: function () {
        var authService = useAuthService();
        var error = {
            title: 'Une erreur est survenue',
            description: "Aucun portail n'est lié a cet utilisateur, vous allez être redirigé vers la page de connexion dans 3 secondes",
        };
        setTimeout(function () {
            authService.doLogout();
        }, 3000);
        return {
            error: error,
        };
    },
});
