import SalesTableUtils from '../CSalesTable/SalesTable.utils';
var FARMERS_ACTIVITY_BY_REGION_TABLE_CONFIG = {
    defaultColDef: { flex: 1, suppressMovable: true, sortable: true, filter: true, wrapHeaderText: true, autoHeaderHeight: true },
    rowSelection: 'single',
    columnDefs: [
        {
            headerName: 'Région',
            field: 'region_name',
            sortable: true,
        },
        {
            headerName: 'Exploitations agricoles inscrites',
            field: 'nb_farmers',
            type: 'rightAligned',
            cellRenderer: 'pTableValueEvolution',
            comparator: SalesTableUtils.sortEvolutionValue,
        },
        {
            headerName: 'Exploitations agricoles actives sur mes produits',
            field: 'nb_active_farmers',
            type: 'rightAligned',
            cellRenderer: 'pTableValueEvolution',
            comparator: SalesTableUtils.sortEvolutionValue,
        },
        {
            headerName: 'Sessions',
            field: 'nb_connections',
            type: 'rightAligned',
            cellRenderer: 'pTableValueEvolution',
            filter: false,
            comparator: SalesTableUtils.sortEvolutionValue,
        },
    ],
};
export default FARMERS_ACTIVITY_BY_REGION_TABLE_CONFIG;
