import { defineComponent, computed, ref } from 'vue';
import Multiselect from 'vue-multiselect';
import { useStore } from '@/store/useStore';
import PCard from '@/presenters/PCard/PCard.vue';
export default defineComponent({
    name: 'CFilter',
    components: {
        Multiselect: Multiselect,
        PCard: PCard,
    },
    setup: function () {
        var store = useStore();
        var user = computed(function () { return store.getters.user; });
        var kinds = ref(user.value.filters.kinds);
        var kindSelected = computed(function () { return store.getters.selectedKindFilter; });
        var subkinds = ref(user.value.filters.subkinds);
        var subkindSelected = computed(function () { return store.getters.selectedSubkindFilter; });
        var products = ref(user.value.filters.products);
        var productSelected = computed(function () { return store.getters.selectedProductFilter; });
        function updateFilters() {
            var tmpKinds = kindSelected.value.length > 0 ? kindSelected.value : user.value.filters.kinds;
            subkinds.value = user.value.filters.subkinds.filter(function (s) { return tmpKinds.map(function (k) { return k.id; }).includes(s.parent); });
            var tmpSubkinds = subkindSelected.value.length > 0 ? subkindSelected.value : subkinds.value;
            products.value = user.value.filters.products.filter(function (p) { return tmpSubkinds.map(function (s) { return s.id; }).includes(p.parent); });
        }
        function selectFilter(selectedFilter, type) {
            store.dispatch('changeSelectedFilter', { type: type, selectedFilter: selectedFilter });
            updateFilters();
        }
        function removeFilter(selectedFilter, type) {
            store.dispatch('removeFilter', { type: type, selectedFilter: selectedFilter });
            updateFilters();
        }
        return {
            kinds: kinds,
            kindSelected: kindSelected,
            selectFilter: selectFilter,
            subkinds: subkinds,
            subkindSelected: subkindSelected,
            products: products,
            productSelected: productSelected,
            removeFilter: removeFilter,
        };
    },
});
