var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s)
                if (Object.prototype.hasOwnProperty.call(s, p))
                    t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from 'vue';
import Vuex from 'vuex';
import { reportingApiService } from '@/services';
Vue.use(Vuex);
var COLORS = ['#b8cac1', '#737494', '#384740', '#848482', '#6082B6', '#AA98A9', '#98817B', '#2A3439'];
export var mutations = {
    SET_FARMER_SUMMARY: function (state, value) {
        state.farmerSummary = value;
    },
    SET_FUNNEL_INFOS: function (state, value) {
        state.funnelInfos = value;
    },
    SET_FARMER_FUNNEL_SUMMARY_INFOS: function (state, value) {
        state.farmerFunnelSummary = value;
    },
    SET_USERS_SUMMARY: function (state, value) {
        state.usersSummary = value;
    },
    SET_FARMER_PRODUCT_ACTIVITY: function (state, value) {
        state.farmerProductActivity = value;
    },
    SET_FARMERS_ACTIVITY_BY_REGION: function (state, value) {
        state.farmersActivityByRegion = value;
    },
    SET_USER_DEVICE_PIE_CHART_DATA: function (state, value) {
        state.userDevicePieChartData = value;
    },
    SET_PRODUCT_VISIT_ORIGIN_PIE_CHART_DATA: function (state, value) {
        state.productVisitOriginPieChartData = value;
    },
    SET_FARMERS_ACTIVITY_BY_DISTRIBUTOR: function (state, value) {
        state.farmersActivityByDistributorChartData = value;
    },
    SET_SESSION_ORIGIN_PIE_CHART_DATA: function (state, value) {
        state.sessionOriginPieChartData = value;
    },
    SET_RANKING_VS_SUPPLIERS_DATA: function (state, value) {
        state.rankingVsSuppliersData = value;
    },
};
export var getters = {
    farmerSummary: function (state) {
        return state.farmerSummary;
    },
    funnelInfos: function (state) {
        return state.funnelInfos;
    },
    farmerFunnelSummary: function (state) {
        return state.farmerFunnelSummary;
    },
    usersSummary: function (state) {
        return state.usersSummary;
    },
    farmerProductActivity: function (state) {
        return state.farmerProductActivity;
    },
    farmersActivityByRegion: function (state) {
        return state.farmersActivityByRegion;
    },
    userDevicePieChartData: function (state) {
        return state.userDevicePieChartData;
    },
    productVisitOriginPieChartData: function (state) {
        return state.productVisitOriginPieChartData;
    },
    farmersActivityByDistributorChartData: function (state) {
        return state.farmersActivityByDistributorChartData;
    },
    sessionOriginPieChartData: function (state) {
        return state.sessionOriginPieChartData;
    },
    rankingVsSuppliersData: function (state) {
        return state.rankingVsSuppliersData;
    },
};
export var actions = {
    fetchFarmerSummary: function (context, _a) {
        var isAuthenticated = _a.isAuthenticated, portalId = _a.portalId, _b = _a.startDate, startDate = _b === void 0 ? '' : _b, _c = _a.endDate, endDate = _c === void 0 ? '' : _c;
        return __awaiter(this, void 0, void 0, function () {
            var summary;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getFarmerSummary(portalId, startDate, endDate)];
                    case 1:
                        summary = _d.sent();
                        context.commit('SET_FARMER_SUMMARY', summary);
                        _d.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchFunnelInfos: function (context) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, isAuthenticated, user, dateRange, selectedFilters, infos;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = context.getters, isAuthenticated = _a.isAuthenticated, user = _a.user, dateRange = _a.dateRange, selectedFilters = _a.selectedFilters;
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getProductFunnel(user.id, dateRange.startDate.toLocaleDateString('fr-FR'), dateRange.endDate.toLocaleDateString('fr-FR'), selectedFilters)];
                    case 1:
                        infos = _b.sent();
                        context.commit('SET_FUNNEL_INFOS', infos);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchFunnelSummaryInfos: function (context) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, isAuthenticated, user, dateRange, selectedFilters, infos;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = context.getters, isAuthenticated = _a.isAuthenticated, user = _a.user, dateRange = _a.dateRange, selectedFilters = _a.selectedFilters;
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getFarmerFunnelSummary(user.id, dateRange.startDate.toLocaleDateString('fr-FR'), dateRange.endDate.toLocaleDateString('fr-FR'), selectedFilters)];
                    case 1:
                        infos = _b.sent();
                        context.commit('SET_FARMER_FUNNEL_SUMMARY_INFOS', infos);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchUsersSummary: function (context) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, isAuthenticated, user, dateRange, selectedFilters, usersSummary;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = context.getters, isAuthenticated = _a.isAuthenticated, user = _a.user, dateRange = _a.dateRange, selectedFilters = _a.selectedFilters;
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getUsersSummary(user.id, dateRange.startDate.toLocaleDateString('fr-FR'), dateRange.endDate.toLocaleDateString('fr-FR'), selectedFilters)];
                    case 1:
                        usersSummary = _b.sent();
                        context.commit('SET_USERS_SUMMARY', usersSummary);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchFarmerProductActivityChartData: function (context) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, isAuthenticated, user, dateRange, selectedFilters, infos;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = context.getters, isAuthenticated = _a.isAuthenticated, user = _a.user, dateRange = _a.dateRange, selectedFilters = _a.selectedFilters;
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getFarmerProductActivity(user.id, dateRange.startDate.toLocaleDateString('fr-FR'), dateRange.endDate.toLocaleDateString('fr-FR'), selectedFilters)];
                    case 1:
                        infos = _b.sent();
                        context.commit('SET_FARMER_PRODUCT_ACTIVITY', infos);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchFarmersActivityByRegion: function (context) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, isAuthenticated, user, dateRange, selectedFilters, infos;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = context.getters, isAuthenticated = _a.isAuthenticated, user = _a.user, dateRange = _a.dateRange, selectedFilters = _a.selectedFilters;
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getFarmersActivityByRegion(user.id, dateRange.startDate.toLocaleDateString('fr-FR'), dateRange.endDate.toLocaleDateString('fr-FR'), selectedFilters)];
                    case 1:
                        infos = _b.sent();
                        context.commit('SET_FARMERS_ACTIVITY_BY_REGION', infos);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchUserDevicePieChartData: function (context) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, isAuthenticated, user, dateRange, selectedFilters, infos;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = context.getters, isAuthenticated = _a.isAuthenticated, user = _a.user, dateRange = _a.dateRange, selectedFilters = _a.selectedFilters;
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getUserDevicePieChartData(user.id, dateRange.startDate.toLocaleDateString('fr-FR'), dateRange.endDate.toLocaleDateString('fr-FR'), selectedFilters)];
                    case 1:
                        infos = _b.sent();
                        infos = infos.map(function (i, idx) { return (__assign(__assign({}, i), { color: COLORS[idx], name: i.name.charAt(0).toUpperCase() + i.name.slice(1) })); });
                        context.commit('SET_USER_DEVICE_PIE_CHART_DATA', infos);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchProductVisitOriginPieChartData: function (context) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, isAuthenticated, user, dateRange, selectedFilters, infos;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = context.getters, isAuthenticated = _a.isAuthenticated, user = _a.user, dateRange = _a.dateRange, selectedFilters = _a.selectedFilters;
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getProductVisitOriginPieChartData(user.id, dateRange.startDate.toLocaleDateString('fr-FR'), dateRange.endDate.toLocaleDateString('fr-FR'), selectedFilters)];
                    case 1:
                        infos = _b.sent();
                        infos = infos.map(function (i, idx) { return (__assign(__assign({}, i), { color: COLORS[idx], name: i.name.charAt(0).toUpperCase() + i.name.slice(1) })); });
                        context.commit('SET_PRODUCT_VISIT_ORIGIN_PIE_CHART_DATA', infos);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchSessionOriginPieChartData: function (context) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, isAuthenticated, user, dateRange, selectedFilters, infos;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = context.getters, isAuthenticated = _a.isAuthenticated, user = _a.user, dateRange = _a.dateRange, selectedFilters = _a.selectedFilters;
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getSessionOriginPieChartData(user.id, dateRange.startDate.toLocaleDateString('fr-FR'), dateRange.endDate.toLocaleDateString('fr-FR'), selectedFilters)];
                    case 1:
                        infos = _b.sent();
                        infos = infos.map(function (i, idx) { return (__assign(__assign({}, i), { color: COLORS[idx], name: i.name.charAt(0).toUpperCase() + i.name.slice(1) })); });
                        context.commit('SET_SESSION_ORIGIN_PIE_CHART_DATA', infos);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchFarmersActivityByDistributorChartData: function (context) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, isAuthenticated, user, dateRange, selectedFilters, infos;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = context.getters, isAuthenticated = _a.isAuthenticated, user = _a.user, dateRange = _a.dateRange, selectedFilters = _a.selectedFilters;
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getUserActivityByDistributor(user.id, dateRange.startDate.toLocaleDateString('fr-FR'), dateRange.endDate.toLocaleDateString('fr-FR'), selectedFilters)];
                    case 1:
                        infos = _b.sent();
                        context.commit('SET_FARMERS_ACTIVITY_BY_DISTRIBUTOR', infos);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchRankingVsSuppliersData: function (context, selectedFilters) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, isAuthenticated, user, dateRange, data;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = context.getters, isAuthenticated = _a.isAuthenticated, user = _a.user, dateRange = _a.dateRange;
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getRankingsVsSuppliers(user.id, dateRange.startDate.toLocaleDateString('fr-FR'), dateRange.endDate.toLocaleDateString('fr-FR'), selectedFilters)];
                    case 1:
                        data = _b.sent();
                        context.commit('SET_RANKING_VS_SUPPLIERS_DATA', data);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
};
var moduleProducts = {
    state: {
        farmerSummary: {},
        funnelInfos: [],
        farmerFunnelSummary: {},
        usersSummary: {},
        farmerProductActivity: {},
        farmersActivityByRegion: {},
        userDevicePieChartData: [],
        productVisitOriginPieChartData: [],
        farmersActivityByDistributorChartData: {},
        sessionOriginPieChartData: [],
        rankingVsSuppliersData: {},
    },
    actions: actions,
    mutations: mutations,
    getters: getters,
};
export default moduleProducts;
