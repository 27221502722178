import SalesTableUtils from '../CSalesTable/SalesTable.utils';
import { useAnalyticService } from '@/plugins/aladinGtm';
var comparator = function (a, b) {
    if (!(a === null || a === void 0 ? void 0 : a.value)) {
        return 1;
    }
    if (!(b === null || b === void 0 ? void 0 : b.value)) {
        return -1;
    }
    return (b === null || b === void 0 ? void 0 : b.value) - (a === null || a === void 0 ? void 0 : a.value);
};
var columnDefs = [
    {
        headerName: 'Gamme',
        field: 'title',
        type: 'leftAligned',
        cellRenderer: 'agGroupCellRenderer',
        cellStyle: { 'white-space': 'normal', wordBreak: 'normal' },
    },
    {
        headerName: 'Nombre de paniers commandés',
        field: 'nb_orders',
        type: 'rightAligned',
        filter: false,
        cellRenderer: 'pTableValueEvolution',
        comparator: comparator,
    },
    {
        headerName: "Chiffre d'affaires (H.T.)",
        field: 'revenue',
        type: 'rightAligned',
        filter: false,
        cellRenderer: 'pTableValueEvolution',
        comparator: comparator,
        valueFormatter: SalesTableUtils.formatCA,
    },
];
var agGridConfig = {
    defaultColDef: { flex: 1, headerClass: 'my_header_class', suppressMovable: true, sortable: true, filter: true },
    masterDetail: true,
    columnDefs: columnDefs,
    enableCellTextSelection: true,
    detailCellRendererParams: function (masterGridParams) {
        return {
            detailGridOptions: {
                context: {
                    masterGrid: {
                        node: masterGridParams.node.parent,
                        data: masterGridParams.data,
                    },
                },
                rowClassRules: {
                    'my-row-odd': function (params) { return params.rowIndex % 2 !== 0; },
                },
                columnDefs: columnDefs,
                enableCellTextSelection: true,
                rowClass: 'row_class_level_3',
                suppressCellFocus: true,
                enableBrowserTooltips: true,
                headerHeight: 0,
                defaultColDef: { flex: 1, sortable: true, filter: true, suppressMovable: true, wrapText: true, autoHeight: true },
            },
            getDetailRowData: function (params) {
                params.successCallback(params.data.kinds);
            },
        };
    },
    onRowGroupOpened: function (params) {
        var analyticService = useAnalyticService();
        // Test true and false because it is called on show with undefined
        if (params.expanded === true) {
            analyticService.trackEvent('page_farmers_click_open_multi_kind', { group_name: params.node.data.name });
            analyticService.removeFromDataLayer('group_name');
        }
        else if (params.expanded === false) {
            analyticService.trackEvent('page_farmers_click_close_multi_kind', { group_name: params.node.data.name });
            analyticService.removeFromDataLayer('group_name');
        }
    },
};
export default agGridConfig;
