import { defineComponent } from 'vue';
import { Chart } from 'highcharts-vue';
import PSolidGaugeChartItem from '@/presenters/PSoildGaugeChart/PSolidGaugeChartItem.vue';
export default defineComponent({
    name: 'p-solid-gauge-chart',
    components: {
        PSolidGaugeChartItem: PSolidGaugeChartItem,
        highcharts: Chart,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        data: {
            type: Object,
            required: true,
        },
        enableTooltip: {
            type: Boolean,
            default: false,
        },
    },
    setup: function (_a) {
        var data = _a.data;
        var getChartOptions = function (item) {
            if (!item)
                return null;
            return {
                title: {
                    text: undefined,
                },
                chart: {
                    type: 'solidgauge',
                    margin: 0,
                    height: null,
                    spacingBottom: 0,
                },
                pane: {
                    size: '140%',
                    center: ['50%', '85%'],
                    startAngle: -90,
                    endAngle: 90,
                    background: [
                        {
                            backgroundColor: '#EEE',
                            innerRadius: '60%',
                            outerRadius: '100%',
                            shape: 'arc',
                        },
                    ],
                },
                tooltip: {
                    enabled: false,
                },
                credits: {
                    enabled: false,
                },
                // the value axis
                yAxis: {
                    min: 0,
                    max: item.total,
                    stops: [
                        [0.1, '#55BF3B'],
                        [0.5, '#DDDF0D'],
                        [0.9, '#DF5353'], // red
                    ],
                    lineWidth: 0,
                    tickWidth: 0,
                    minorTickInterval: null,
                    tickInterval: 0,
                    title: {
                        y: -70,
                    },
                    labels: {
                        y: 16,
                    },
                },
                plotOptions: {
                    solidgauge: {
                        dataLabels: {
                            y: 5,
                            borderWidth: 0,
                            useHTML: true,
                        },
                    },
                },
                series: [
                    {
                        type: 'solidgauge',
                        data: [item.rank],
                        dataLabels: {
                            enabled: false,
                        },
                    },
                ],
            };
        };
        var getChartLabel = function (item) {
            if (!item)
                return null;
            return {
                unit: 'Votre rang',
                value: "".concat(item.rank, " sur ").concat(item.total),
            };
        };
        var sessionViewProductPageOptions = {
            title: 'Sessions ayant consulté une de vos fiches produits',
            label: getChartLabel(data.session_page_product_view),
            options: getChartOptions(data.session_page_product_view),
        };
        var averageProductBySessionOptions = {
            title: 'Nombre moyen de vos produits visités par session',
            label: getChartLabel(data.average_page_product_view_by_session),
            options: getChartOptions(data.average_page_product_view_by_session),
        };
        var averageCartOptions = {
            title: 'Panier moyen',
            label: getChartLabel(data.average_cart),
            options: getChartOptions(data.average_cart),
        };
        var reachedFarmersOptions = {
            title: 'Exploitations agricoles touchées',
            label: getChartLabel(data.reached_farmers),
            options: getChartOptions(data.reached_farmers),
        };
        return {
            sessionViewProductPageOptions: sessionViewProductPageOptions,
            averageProductBySessionOptions: averageProductBySessionOptions,
            averageCartOptions: averageCartOptions,
            reachedFarmersOptions: reachedFarmersOptions,
        };
    },
});
