import { AuthService } from './auth.service';
export var AuthSymbol = Symbol('auth-service');
// Note: Not using provide/inject for compatibility reasons with
// vue router, as we need to call the authService within the
// beforeEach guard, and injection is not available there.
var authService;
export function provideAuthService(config) {
    authService = new AuthService(config);
}
export function useAuthService() {
    if (!authService) {
        throw new Error('AuthService was not initialized');
    }
    return authService;
}
