import { formatService } from '@/services';
import { useAnalyticService } from '@/plugins/aladinGtm';
/* eslint-disable-next-line */
export var HEADER;
(function (HEADER) {
    HEADER["GAMME"] = "gamme";
    HEADER["PRODUITREF"] = "produit ref.";
    HEADER["PRODUITSVENTE"] = "produitsvente";
    HEADER["DISTRIBUTEURS"] = "distributeurs";
})(HEADER || (HEADER = {}));
/* eslint-disable-next-line */
export var HEADER_SOUS_CATEGORIE;
(function (HEADER_SOUS_CATEGORIE) {
    HEADER_SOUS_CATEGORIE["GAMME"] = "SC_gamme";
    HEADER_SOUS_CATEGORIE["PRODUITREF"] = "SC_produit ref.";
    HEADER_SOUS_CATEGORIE["PRODUITSVENTE"] = "SC_produitsvente";
    HEADER_SOUS_CATEGORIE["DISTRIBUTEURS"] = "SC_distributeurs";
})(HEADER_SOUS_CATEGORIE || (HEADER_SOUS_CATEGORIE = {}));
/* eslint-disable-next-line */
export var HEADER_PRODUIT;
(function (HEADER_PRODUIT) {
    HEADER_PRODUIT["PRODUIT"] = "P_produit";
    HEADER_PRODUIT["FABRICANT"] = "P_fabricant";
    HEADER_PRODUIT["PHOTO"] = "P_photo";
    HEADER_PRODUIT["NOTES"] = "P_notes";
    HEADER_PRODUIT["DISTRIBUTEURS"] = "P_distributeurs";
    HEADER_PRODUIT["PRIXVENTE"] = "P_prixDeVente";
})(HEADER_PRODUIT || (HEADER_PRODUIT = {}));
var CatalogTableUtils = /** @class */ (function () {
    function CatalogTableUtils() {
    }
    CatalogTableUtils.getHeader = function (header) {
        return CatalogTableUtils.headerMap.get(header);
    };
    CatalogTableUtils.getSecondLevelHeader = function (header) {
        return CatalogTableUtils.headerMap.get(header);
    };
    CatalogTableUtils.getLastLevelHeader = function (header) {
        return CatalogTableUtils.headerMap.get(header);
    };
    CatalogTableUtils.getLastLevelRenderer = function (columnDefs) {
        return function (masterGridParams) {
            return {
                detailGridOptions: {
                    context: {
                        masterGrid: {
                            node: masterGridParams.node.parent,
                            data: masterGridParams.data,
                        },
                    },
                    rowClassRules: {
                        'my-row-odd': CatalogTableUtils.colorRowOddForLastLevel,
                    },
                    columnDefs: columnDefs,
                    suppressCellFocus: true,
                    headerHeight: 32,
                    defaultColDef: { flex: 1, sortable: true, filter: true, type: 'rightAligned', suppressMovable: true },
                },
                getDetailRowData: function (params) {
                    params.successCallback(params.data.products);
                },
            };
        };
    };
    CatalogTableUtils.getSecondLevelRenderer = function (columnDefs, detailCellRendererParams) {
        return {
            detailGridOptions: {
                detailRowAutoHeight: true,
                columnDefs: columnDefs,
                defaultColDef: { flex: 1, sortable: true, filter: true },
                rowClass: 'row_class_level_1',
                masterDetail: true,
                headerHeight: 0,
                suppressRowHoverHighlight: true,
                suppressCellFocus: true,
                rowClassRules: {
                    'my-row-odd': CatalogTableUtils.colorRowOddForSecondLevel,
                },
                detailCellRendererParams: detailCellRendererParams,
                onRowGroupOpened: function (params) {
                    var analyticService = useAnalyticService();
                    // Test true and false because it is called on show with undefined
                    if (params.expanded === true) {
                        analyticService.trackEvent('page_catalog_click_open_subkind', { group_name: params.node.data.name });
                        analyticService.removeFromDataLayer('group_name');
                    }
                    else if (params.expanded === false) {
                        analyticService.trackEvent('page_catalog_click_close_subkind', { group_name: params.node.data.name });
                        analyticService.removeFromDataLayer('group_name');
                    }
                },
            },
            getDetailRowData: function (params) {
                params.successCallback(params.data.categories);
            },
        };
    };
    CatalogTableUtils.formatCA = function (params) {
        return formatService.formatCA(params.value).replace('.', ',');
    };
    CatalogTableUtils.formatMarketShare = function (params) {
        return formatService.formatMarketShare(params.value);
    };
    CatalogTableUtils.colorRowOddForLastLevel = function (params) {
        return params.context.masterGrid.node.childIndex % 2 !== 0;
    };
    CatalogTableUtils.colorRowOddForSecondLevel = function (params) {
        return params.rowIndex % 2 !== 0;
    };
    CatalogTableUtils.headerMap = new Map()
        .set(HEADER.GAMME, {
        headerName: 'Gamme',
        field: 'name',
        cellRenderer: 'agGroupCellRenderer',
        minWidth: 350,
    })
        .set(HEADER.PRODUITREF, {
        headerName: 'Produits Réf.',
        field: 'referenced_products',
        type: 'rightAligned',
    })
        .set(HEADER.PRODUITSVENTE, {
        headerName: 'Produits à la vente',
        field: 'products_for_sale',
        type: 'rightAligned',
    })
        .set(HEADER.DISTRIBUTEURS, {
        headerName: 'Distributeurs',
        field: 'distributors',
        cellRenderer: 'pDistributorsModal',
        type: 'rightAligned',
        onCellClicked: function (event) {
            var analyticService = useAnalyticService();
            analyticService.trackEvent('page_catalog_click_kind_distributor', {
                additionnal_data: event.data.name,
            });
            analyticService.removeFromDataLayer('additionnal_data');
        },
    })
        .set(HEADER_SOUS_CATEGORIE.GAMME, {
        field: 'name',
        cellRenderer: 'agGroupCellRenderer',
        sortable: true,
        minWidth: 350,
    })
        .set(HEADER_SOUS_CATEGORIE.PRODUITREF, {
        field: 'referenced_products',
        type: 'rightAligned',
    })
        .set(HEADER_SOUS_CATEGORIE.PRODUITSVENTE, {
        field: 'products_for_sale',
        type: 'rightAligned',
    })
        .set(HEADER_SOUS_CATEGORIE.DISTRIBUTEURS, {
        field: 'distributors',
        cellRenderer: 'pDistributorsModal',
        type: 'rightAligned',
        onCellClicked: function (event) {
            var analyticService = useAnalyticService();
            analyticService.trackEvent('page_catalog_click_subkind_distributor', {
                additionnal_data: event.data.name,
            });
            analyticService.removeFromDataLayer('additionnal_data');
        },
    })
        .set(HEADER_PRODUIT.PRODUIT, {
        headerName: 'Produit',
        field: 'name',
        headerClass: 'last-level-first-column-header',
        cellClass: 'last-level-first-column-cell',
        resizable: true,
    })
        .set(HEADER_PRODUIT.PHOTO, {
        headerName: 'Photo',
        field: 'url_image',
        type: 'rightAligned',
        cellRenderer: 'pCheckPhoto',
    })
        .set(HEADER_PRODUIT.NOTES, {
        headerName: 'Notes',
        valueGetter: function (params) {
            return ({
                hasImage: params.data.has_image,
                hasDescription: params.data.has_description,
                hasProductSheet: params.data.has_product_sheet,
            });
        },
        type: 'rightAligned',
        cellRenderer: 'pProductSheetScore',
        onCellClicked: function (event) {
            var analyticService = useAnalyticService();
            analyticService.trackEvent('page_catalog_click_rating', {
                additionnal_data: event.data.name,
                product_catalog_has_image: event.data.has_image,
                product_catalog_has_description: event.data.has_description,
                product_catalog_has_product_sheet: event.data.has_product_sheet,
            });
            analyticService.removeFromDataLayer('additionnal_data');
            analyticService.removeFromDataLayer('product_catalog_has_image');
            analyticService.removeFromDataLayer('product_catalog_has_description');
            analyticService.removeFromDataLayer('product_catalog_has_product_sheet');
        },
    })
        .set(HEADER_PRODUIT.DISTRIBUTEURS, {
        headerName: 'Distributeurs',
        field: 'distributors',
        cellRenderer: 'pDistributorsModal',
        onCellClicked: function (event) {
            var analyticService = useAnalyticService();
            analyticService.trackEvent('page_catalog_click_product_distributor', {
                additionnal_data: event.data.name,
            });
            analyticService.removeFromDataLayer('additionnal_data');
        },
    })
        .set(HEADER_PRODUIT.PRIXVENTE, {
        headerName: 'Prix de vente',
        field: 'average_price',
        valueFormatter: CatalogTableUtils.formatCA,
    });
    return CatalogTableUtils;
}());
export default CatalogTableUtils;
