var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ref } from 'vue';
import FeatureFlippingService from '@/services/featureFlipping.service';
export var featureFlippingServiceSymbol = Symbol('featureFlippingService');
export var shoppingListFeatureSymbol = Symbol('shoppingListFeature');
var featureFlippingService;
var isSalesByCatEnabled;
var isNewViewAgriEnabled;
var isSalesByKindV3Enabled;
var isFarmersPageV3Enabled;
var isHomePageV3Enabled;
var isSalesPageV3Enabled;
var isCatalogPageV3Enabled;
var isPieChartsDisplayed;
var farmersActivityByDistributors;
var isConnexionOriginsDisplayed;
var isRankingByKindDisplayed;
var features;
/**
 * @deprecated For test only
 */
export function reset() {
    featureFlippingService = undefined;
}
export function provideFeatureFlipping(featureFlippingApiClient) {
    featureFlippingService = new FeatureFlippingService(featureFlippingApiClient);
    isSalesByCatEnabled = ref(false);
    isNewViewAgriEnabled = ref(false);
    isSalesByKindV3Enabled = ref(false);
    isFarmersPageV3Enabled = ref(false);
    isHomePageV3Enabled = ref(false);
    isSalesPageV3Enabled = ref(false);
    isCatalogPageV3Enabled = ref(false);
    isPieChartsDisplayed = ref(false);
    farmersActivityByDistributors = ref(false);
    isConnexionOriginsDisplayed = ref(false);
    isRankingByKindDisplayed = ref(false);
    features = {
        'aladin:front-fournisseur:sales:table-sales-by-cat': isSalesByCatEnabled,
        'aladin:front-fournisseur:show-agri-view': isNewViewAgriEnabled,
        'aladin:front-fournisseur:sales:sales-by-kind-v3': isSalesByKindV3Enabled,
        'aladin:front-fournisseur:farmers-page-v3': isFarmersPageV3Enabled,
        'aladin:front-fournisseur:sales-page-v3': isSalesPageV3Enabled,
        'aladin:front-fournisseur:catalog-page-v3': isCatalogPageV3Enabled,
        'aladin:front-fournisseur:home-page-v3': isHomePageV3Enabled,
        'aladin:front-fournisseur:pie-charts': isPieChartsDisplayed,
        'aladin:front-fournisseur:farmers-activity-by-distributors': farmersActivityByDistributors,
        'aladin:front-fournisseur:connexion-origins-pie-chart': isConnexionOriginsDisplayed,
        'aladin:front-fournisseur:ranking-by-kind-chart': isRankingByKindDisplayed,
    };
}
export function useFeatureFlipping() {
    var _this = this;
    return {
        get isSalesByCatEnabled() {
            return isSalesByCatEnabled;
        },
        get isNewViewAgriEnabled() {
            return isNewViewAgriEnabled;
        },
        get isSalesByKindV3Enabled() {
            return isSalesByKindV3Enabled;
        },
        get isFarmersPageV3Enabled() {
            return isFarmersPageV3Enabled;
        },
        get isSalesPageV3Enabled() {
            return isSalesPageV3Enabled;
        },
        get isCatalogPageV3Enabled() {
            return isCatalogPageV3Enabled;
        },
        get isHomePageV3Enabled() {
            return isHomePageV3Enabled;
        },
        get isPieChartsDisplayed() {
            return isPieChartsDisplayed;
        },
        get farmersActivityByDistributors() {
            return farmersActivityByDistributors;
        },
        get isConnexionOriginsDisplayed() {
            return isConnexionOriginsDisplayed;
        },
        get isRankingByKindDisplayed() {
            return isRankingByKindDisplayed;
        },
        updateFeatureFlipping: function (partnerId) {
            return __awaiter(_this, void 0, void 0, function () {
                var checkAllResponse_1, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!featureFlippingService)
                                throw new Error('FeatureFlippingService is not initialized');
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, featureFlippingService.checkAllFeatures(partnerId)];
                        case 2:
                            checkAllResponse_1 = _a.sent();
                            Object.keys(features).forEach(function (featureKey) {
                                var checkFeature = checkAllResponse_1.find(function (feature) { return feature.id === featureKey; });
                                features[featureKey].value = checkFeature ? checkFeature.active : false;
                            });
                            return [3 /*break*/, 4];
                        case 3:
                            error_1 = _a.sent();
                            // eslint-disable-next-line no-console
                            console.error(error_1);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
    };
}
