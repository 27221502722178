<template>
  <c-vertical-slider-navigation
    :class="{ 'c-menu--open': isSliderOpen }"
    :menuObject="menuObject"
    :activeId="activeId"
    @clicked="onNavigationItemClicked($event)"
    @sliderOpen="isSliderOpen = true"
    @sliderClose="isSliderOpen = false"
  >
    <template v-slot:header>
      <h1 class="c-menu__header">
        <adn-logo-aladin
          class="c-menu__header__fullimg"
          :christmasMode="christmasModeEnabled()"
          :homepageUrl="{ name: 'home' }"
          :showPreviousButton="false"
          :brightMode="true"
          :environmentName="environmentName()"
          :cooperativeName="user.portal_name"
        >
        </adn-logo-aladin>
        <img class="c-menu__header__logo" src="~@b2ag/design-system/dist/themes/aladin/assets/logos/icon_aladin.svg" alt="aladin icon" />
      </h1>
    </template>
    <template v-slot:footer>
      <div class="c-menu__footer">
        <div class="c-menu__footer__update">Données mises à jour toutes les heures</div>
        <adn-button class="c-menu__footer__btn" @click="logout()" kind="link" iconName="navigation-disconnect" iconPosition="left">
          <span class="c-menu__footer__logout-label">Déconnexion</span>
        </adn-button>
      </div>
    </template>
  </c-vertical-slider-navigation>
</template>

<script>
import AdnLogoAladin from '@b2ag/design-system/dist/components/AdnLogoAladin/AdnLogoAladin.vue'
import AdnButton from '@b2ag/design-system/dist/components/AdnButton/AdnButton.vue'
import { computed, defineComponent, ref, watch, onBeforeMount } from 'vue'
import { useRoute } from 'vue-router/composables'
import { useAuthService } from '@/services/auth'
import { useStore } from '@/store/useStore'
import { useRouter } from '@/services/router/useRouter'
import CVerticalSliderNavigation from '@/components/CVerticalSliderNavigation/CVerticalSliderNavigation.vue'

export default defineComponent({
  name: 'CMenu',
  components: {
    CVerticalSliderNavigation,
    AdnButton,
    AdnLogoAladin,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const activeId = ref('')
    const isSliderOpen = ref(false)

    const idPathMap = new Map()
      .set(`home`, `/`)
      .set(`catalog`, `/catalogue`)
      .set(`sales`, `/ventes`)
      .set(`salesBySupplier`, `/ventes/distributeurs`)
      .set(`salesByRegion`, `/ventes/region`)
      .set('buyers', `/acheteurs`)
      .set('agriculturalExploitations', '/exploitations-agricoles')

    const features = computed(() => store.getters.user.features)
    const user = computed(() => store.getters.user)
    const menuObject = computed(() => {
      const menuObj = []
      const salesChildren = [
        { label: 'Synthèse', icon: '', id: 'sales' },
        { label: 'Par distributeur', icon: '', id: 'salesBySupplier' },
      ]

      if (features.value.includes('salesByRegion')) {
        salesChildren.push({ label: 'Par région', isBeta: true, icon: '', id: 'salesByRegion' })
      }

      menuObj.push({ id: 'home', label: 'Accueil', icon: 'dashboard-outlined' })
      menuObj.push({ id: 'catalog', label: 'Catalogue', icon: 'book-outlined' })
      menuObj.push({ id: 'sales', label: 'Ventes', icon: 'truck-outlined', children: salesChildren })

      if (features.value.includes('agriculturalExploitations')) {
        menuObj.push({
          id: 'agriculturalExploitations',
          label: 'Exploitations Agricoles',
          icon: 'people-outlined',
        })
      }

      menuObj.push({ label: 'Distributeurs', id: 'buyers', icon: 'store-outlined' })
      return menuObj
    })

    onBeforeMount(() => {
      for (const [id, path] of idPathMap.entries()) {
        if (route.path.includes(path)) {
          activeId.value = id
        }
      }
    })

    watch(
      () => route.path,
      async (routePath) => {
        for (const [id, path] of idPathMap.entries()) {
          if (routePath.includes(path)) {
            activeId.value = id
          }
        }
      }
    )

    function logout() {
      const authService = useAuthService()
      authService.doLogout()
    }

    function christmasModeEnabled() {
      return window.env && window.env.LOGO_CHRISTMAS_MODE === 'yes'
    }

    function environmentName() {
      return window.env && window.env.ENVIRONMENT_NAME
    }

    function onNavigationItemClicked(id) {
      router.push({
        name: id,
      })
    }

    return {
      menuObject,
      isSliderOpen,
      activeId,
      user,
      logout,
      christmasModeEnabled,
      environmentName,
      onNavigationItemClicked,
    }
  },
})
</script>

<style lang="scss" scope>
.c-menu {
  &__header {
    display: inline-block;
    margin: $spacing-4;
    height: 4rem;
    &__fullimg {
      display: none;
    }
    &__logo {
      display: block;
      height: 4rem;
    }
    &::after {
      background-color: var(--idf-color-neutral-500);
      content: '';
      height: 0.1rem;
      left: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      display: block;
    }
  }

  &__footer {
    text-align: center;
    &__update {
      font-size: $font-size-xs;
      line-height: $line-height-xs;
      display: none;
      margin-bottom: $spacing-3;
    }
    &__btn {
      color: var(--idf-color-neutral-100) !important;
      margin: $spacing-2 0;
      position: relative;
      width: 100%;
      &::before {
        background-color: var(--idf-color-neutral-500);
        content: '';
        height: 0.1rem;
        left: 0;
        position: absolute;
        right: 0;
        top: -1rem;
        display: block;
      }
    }

    &__logout-label {
      display: none;
    }
  }
}

.c-menu--open {
  .c-menu {
    &__header {
      &__fullimg {
        display: block;
      }
      &__logo {
        display: none;
      }
    }

    &__footer {
      &__update {
        display: block;
      }
      &__logout-label {
        display: block;
      }
    }
  }
}

.vertical-slider-navigation__parents {
  margin-top: $spacing-4;
}
</style>
