var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue';
import { getAgGridConfig } from './SalesByKindByPartnerTable.config';
import SalesTableUtils, { HEADER_PRODUIT, HEADER_SOUS_CATEGORIE, HEADER } from './SalesTable.utils';
import { useStore } from '@/store/useStore';
import CSearchBarV3 from '@/components/CSearchBarV3/CSearchBarV3.vue';
import PThreeLvlTable from '@/presenters/PThreeLvlTable/PThreeLvlTable.vue';
import PLoadSpinner from '@/presenters/PLoadSpinner/PLoadSpinner.vue';
import PPartnerLogo from '@/presenters/PPartnerLogo/PPartnerLogo.vue';
import PTableValueEvolution from '@/presenters/PTableValueEvolution/PTableValueEvolution.vue';
import PEventButton from '@/presenters/PEventButton/PEventButton.vue';
import { formatService } from '@/services';
import { useAnalyticService } from '@/plugins/aladinGtm';
export default defineComponent({
    name: 'CSalesByPartner',
    components: {
        PEventButton: PEventButton,
        CSearchBarV3: CSearchBarV3,
        PLoadSpinner: PLoadSpinner,
        PThreeLvlTable: PThreeLvlTable,
        PPartnerLogo: PPartnerLogo,
        PTableValueEvolution: PTableValueEvolution,
    },
    setup: function () {
        var store = useStore();
        var user = computed(function () { return store.getters.user; });
        var salesByPartner = computed(function () { return store.getters.salesByPartnerV3; });
        var kindFilters = computed(function () { return user.value.filters.kinds; });
        var searchBar = ref();
        var selectedSalesData = ref([]);
        var isLoaded = ref(false);
        var analyticService = useAnalyticService();
        var agGridConfig = ref();
        function fetchSalesByPartner() {
            return __awaiter(this, void 0, void 0, function () {
                var e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            isLoaded.value = false;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, store.dispatch('fetchSalesByPartnerV3')];
                        case 2:
                            _a.sent();
                            isLoaded.value = true;
                            return [3 /*break*/, 4];
                        case 3:
                            e_1 = _a.sent();
                            console.log('Cannot retrieve sales by partner V3', e_1);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        }
        function getProductColDefs() {
            var productColDefs = [
                SalesTableUtils.getLastLevelHeader(HEADER_PRODUIT.NOM),
                SalesTableUtils.getLastLevelHeader(HEADER_PRODUIT.NOMBREVENTE),
            ];
            if (user.value.filters.kinds.some(function (s) { return s.id === 'semences'; })) {
                productColDefs.push(SalesTableUtils.getLastLevelHeader(HEADER_PRODUIT.SURFACE));
            }
            productColDefs.push(SalesTableUtils.getLastLevelHeader(HEADER_PRODUIT.CA));
            return productColDefs;
        }
        function getSubkindColDefs() {
            var subkindColDefs = [
                SalesTableUtils.getSecondLevelHeader(HEADER_SOUS_CATEGORIE.NOM),
                SalesTableUtils.getSecondLevelHeader(HEADER_SOUS_CATEGORIE.NOMBREVENTE),
                SalesTableUtils.getSecondLevelHeader(HEADER_SOUS_CATEGORIE.PARTMARCHE),
            ];
            if (user.value.filters.kinds.some(function (s) { return s.id === 'semences'; })) {
                subkindColDefs.push(SalesTableUtils.getSecondLevelHeader(HEADER_SOUS_CATEGORIE.SURFACE));
            }
            subkindColDefs.push(SalesTableUtils.getSecondLevelHeader(HEADER_SOUS_CATEGORIE.CA));
            return subkindColDefs;
        }
        function getKindColDefs() {
            var kindColDefs = [
                SalesTableUtils.getHeader(HEADER.GAMME),
                SalesTableUtils.getHeader(HEADER.VENTE),
                SalesTableUtils.getHeader(HEADER.PARTMARCHE),
            ];
            if (user.value.filters.kinds.some(function (s) { return s.id === 'semences'; })) {
                kindColDefs.push(SalesTableUtils.getHeader(HEADER.SURFACE));
            }
            kindColDefs.push(SalesTableUtils.getHeader(HEADER.CA));
            return kindColDefs;
        }
        function initAgGridConfig() {
            agGridConfig.value = getAgGridConfig(getKindColDefs(), getSubkindColDefs(), getProductColDefs());
        }
        watch(computed(function () { return store.getters.dateRange; }), fetchSalesByPartner);
        watch(computed(function () { return store.getters.selectedFilters; }), fetchSalesByPartner);
        onBeforeMount(function () {
            initAgGridConfig();
            fetchSalesByPartner();
        });
        watch(salesByPartner, function () {
            selectedSalesData.value = salesByPartner.value;
        });
        watch(kindFilters, initAgGridConfig);
        function onSearch(searchResult) {
            selectedSalesData.value = searchResult;
        }
        function formatCA(value) {
            return formatService.formatCA(value.toString()).replace('.', ',');
        }
        function exportSales(partnerId) {
            return function () {
                analyticService.trackEvent('page_sales_partner_click_button_export', { additionnal_data: partnerId });
                analyticService.removeFromDataLayer('additionnal_data');
                store.dispatch('exportSalesByKindByPartner', partnerId);
            };
        }
        return {
            onSearch: onSearch,
            exportSales: exportSales,
            agGridConfig: agGridConfig,
            salesByPartner: salesByPartner,
            selectedSalesData: selectedSalesData,
            searchBar: searchBar,
            isLoaded: isLoaded,
            formatCA: formatCA,
        };
    },
});
