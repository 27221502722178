var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s)
                if (Object.prototype.hasOwnProperty.call(s, p))
                    t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from 'vue';
import Vuex from 'vuex';
import { getColor, getSymbol } from './category-symbol-color.utils';
import { reportingApiService } from '@/services';
Vue.use(Vuex);
export var mutations = {
    SET_SOLD_PRODUCTS_BY_CATEGORY: function (state, value) {
        state.boughtProductsByCategory = value;
    },
    SET_VISITED_PRODUCTS_BY_CATEGORY: function (state, value) {
        state.visitedProductsByCategory = value;
    },
    SET_PRODUCT_CATALOG: function (state, value) {
        state.productCatalog = value;
    },
    SET_CATALOG_WITH_PARTNER_NAMES: function (state, value) {
        state.catalogWithPartnerNames = value;
    },
    SET_CATALOG_SUMMARY: function (state, value) {
        state.catalogSummary = value;
    },
};
export var getters = {
    boughtProductsByCategory: function (state) {
        return state.boughtProductsByCategory;
    },
    visitedProductsByCategory: function (state) {
        return state.visitedProductsByCategory;
    },
    productCatalog: function (state) {
        return state.productCatalog;
    },
    catalogWithPartnerNames: function (state) {
        return state.catalogWithPartnerNames;
    },
    catalogSummary: function (state) {
        return state.catalogSummary;
    },
};
export var actions = {
    fetchProductCatalog: function (context, _a) {
        var isAuthenticated = _a.isAuthenticated, portalId = _a.portalId, _b = _a.startDate, startDate = _b === void 0 ? '' : _b, _c = _a.endDate, endDate = _c === void 0 ? '' : _c;
        return __awaiter(this, void 0, Promise, function () {
            var products;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getProductCatalog(portalId, startDate, endDate)];
                    case 1:
                        products = _d.sent();
                        context.commit('SET_PRODUCT_CATALOG', products);
                        _d.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchBoughtProductsByCategory: function (context, _a) {
        var isAuthenticated = _a.isAuthenticated, portalId = _a.portalId, _b = _a.startDate, startDate = _b === void 0 ? '' : _b, _c = _a.endDate, endDate = _c === void 0 ? '' : _c;
        return __awaiter(this, void 0, Promise, function () {
            var products;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getProductsBoughtByCategory(portalId, startDate, endDate)];
                    case 1:
                        products = _d.sent();
                        products.categories = products.categories.map(function (c) {
                            var catWithSymbol = __assign({}, c);
                            catWithSymbol.color = getColor(c.name);
                            catWithSymbol.symbol = getSymbol(c.name);
                            return catWithSymbol;
                        });
                        context.commit('SET_SOLD_PRODUCTS_BY_CATEGORY', products);
                        _d.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchVisitedProductsByCategory: function (context, _a) {
        var isAuthenticated = _a.isAuthenticated, portalId = _a.portalId, _b = _a.startDate, startDate = _b === void 0 ? '' : _b, _c = _a.endDate, endDate = _c === void 0 ? '' : _c;
        return __awaiter(this, void 0, Promise, function () {
            var products;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getProductsVisitedByCategory(portalId, startDate, endDate)];
                    case 1:
                        products = _d.sent();
                        products.categories = products.categories.map(function (c) {
                            var catWithSymbol = __assign({}, c);
                            catWithSymbol.color = getColor(c.name);
                            catWithSymbol.symbol = getSymbol(c.name);
                            return catWithSymbol;
                        });
                        context.commit('SET_VISITED_PRODUCTS_BY_CATEGORY', products);
                        _d.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchCatalogSummary: function (context) {
        return __awaiter(this, void 0, Promise, function () {
            var _a, isAuthenticated, user, dateRange, selectedFilters, summary;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = context.getters, isAuthenticated = _a.isAuthenticated, user = _a.user, dateRange = _a.dateRange, selectedFilters = _a.selectedFilters;
                        if (!isAuthenticated)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, reportingApiService.getCatalogSummary(user.id, dateRange.startDate.toLocaleDateString('fr-FR'), dateRange.endDate.toLocaleDateString('fr-FR'), selectedFilters)];
                    case 1:
                        summary = _b.sent();
                        context.commit('SET_CATALOG_SUMMARY', summary);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    setProductCatalogWithPartnerNames: function (context, catalog) {
        context.commit('SET_CATALOG_WITH_PARTNER_NAMES', catalog);
    },
};
var moduleProducts = {
    state: {
        boughtProductsByCategory: {},
        visitedProductsByCategory: {},
        productCatalog: [],
        catalogWithPartnerNames: [],
        catalogSummary: null,
    },
    actions: actions,
    mutations: mutations,
    getters: getters,
};
export default moduleProducts;
