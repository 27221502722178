import { defineComponent } from 'vue';
import { Chart } from 'highcharts-vue';
export default defineComponent({
    name: 'p-solid-gauge-chart-item',
    components: {
        highcharts: Chart,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        label: {
            type: Object,
            default: '',
        },
        options: {
            type: Object,
            required: true,
        },
    },
    setup: function (_a) {
        var options = _a.options, label = _a.label;
        var hasContent = Boolean(options) && Boolean(label);
        return {
            hasContent: hasContent,
        };
    },
});
