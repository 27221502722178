const formatService = {
  formatCA: (currency) => {
    if (!currency) return '- €'
    const formattedCurrency = formatService.formatNumber(currency)
    return `${formattedCurrency} €`
  },
  formatNumber: (number) => {
    if (!number) return '0'
    return `${String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`
  },
  formatMarketShare: (marketShare) => {
    if (marketShare == null || marketShare === '-') return '- %'
    return `${marketShare.toString().replace('.', ',')} %`
  },
}

export default formatService
