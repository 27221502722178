<template>
  <div class="v-sales">
    <h1 class="bold">Ventes par distributeur</h1>
    <div class="v-sales__datepicker">
      <c-date-picker />
    </div>
    <div class="v-sales__filters">
      <c-filter />
    </div>
    <p-card>
      <c-sales-summary />
    </p-card>
    <p-card>
      <c-sales-by-partner />
    </p-card>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import CDatePicker from '@/components/CDatepicker/CDatePicker.vue'
import PCard from '@/presenters/PCard/PCard.vue'
import CFilter from '@/components/CFilter/CFilter.vue'
import CSalesSummary from '@/components/CSalesSummary/CSalesSummary.vue'
import CSalesByPartner from '@/components/CSalesByPartner/CSalesByPartner.vue'

export default defineComponent({
  name: 'VSalesBySupplier',
  components: {
    CDatePicker,
    CFilter,
    CSalesByPartner,
    CSalesSummary,
    PCard,
  },
})
</script>
