import { provide, inject } from 'vue'

export const routerSymbol = Symbol('router')

export function provideRouter(router) {
  provide(routerSymbol, router)
}

export const useRouter = () => {
  const router = inject(routerSymbol)
  return router
}
