/* eslint-disable no-shadow */
import * as Sentry from '@sentry/browser'
import Vue from 'vue'
import Vuex from 'vuex'
import productModule from './products.store'
import salesModule from './sales.store'
import farmerModule from './farmer.store'
import dateModule from './date.store'
import filterModule from './filter.store'
import { offerApiService, reportingApiService } from '../services'
import API_ERROR from '../services/api-errors'
import { useAuthService } from '../services/auth'
import { useFeatureFlipping } from '../composables/useFeatureFlipping'
import salesV3Module from '@/store/salesv3.store'
import exportModule from '@/store/export.store'

Vue.use(Vuex)

export const defaultUser = {
  id: '',
  portal_name: '',
  features: [],
  filters: {
    kinds: [],
    subkinds: [],
    products: [],
  },
}
export const defaultApp = {
  isAuthenticated: false,
}

const FILTERED_PARTNERS_ID = [12, 13, 28]

const state = {
  App: defaultApp,
  user: defaultUser,
  userSales: {},
  userTotalSales: {},
  userMonthlySales: [],
  userProducts: {
    categories_level_0: [],
  },
  partnerSummary: {
    metrics: { global: {}, supplier: { sellers: [] } },
  },
  partners: [],
  isStoreInitialized: false,
}
export const mutations = {
  SET_USER(state, supplier) {
    state.user = supplier
    if (window.env.SENTRY_ENABLED === 'yes') {
      Sentry.setUser({
        id: supplier.id,
        portal_name: supplier.portal_name,
      })
    }
  },
  SET_AUTHENTICATED(state, isAuthenticated) {
    state.App.isAuthenticated = isAuthenticated
  },
  SET_USER_PRODUCTS(state, sales) {
    state.userProducts = sales
  },
  SET_PARTNER_SUMMARY(state, partnerSummary) {
    state.partnerSummary = partnerSummary
  },
  SET_INITIALIZATION_STATE(state, value) {
    state.isStoreInitialized = value
  },
  SET_PARTNERS(state, value) {
    state.partners = value
  },
}
export const getters = {
  user(state) {
    return state.user
  },
  isAuthenticated(state) {
    return state.App.isAuthenticated
  },
  isStoreInitialized(state) {
    return state.isStoreInitialized
  },
  userProducts(state) {
    return state.userProducts
  },
  globalSummary(state) {
    return state.partnerSummary.metrics.global
  },
  partnerSummary(state) {
    return state.partnerSummary.metrics.supplier
  },
  partners(state) {
    return state.partners
  },
}
export const actions = {
  async init(context) {
    await context.dispatch('fetchUser')

    if (context.getters.user === API_ERROR.NOT_FOUND) {
      context.commit('SET_INITIALIZATION_STATE', false)
    } else {
      await useFeatureFlipping().updateFeatureFlipping(context.getters.user.id)
      context.commit('SET_INITIALIZATION_STATE', true)
    }
  },
  async fetchUser(context) {
    const authService = useAuthService()
    const res = await reportingApiService.getSupplier()
    const isAuthenticated = res === API_ERROR.NOT_FOUND ? false : await authService.isAuthenticated()

    context.commit('SET_USER', res)
    context.commit('SET_AUTHENTICATED', isAuthenticated)
  },
  async fetchPartnerSummary(context) {
    if (context.getters.isAuthenticated) {
      const portalId = context.getters.user.id
      const partnerSummary = await reportingApiService.getPartnerSummary(portalId)
      context.commit('SET_PARTNER_SUMMARY', partnerSummary)
    }
  },
  async fetchPartners(context) {
    const cooperatives = await offerApiService.getCooperatives()
    if (cooperatives) {
      const filteredCooperatives = cooperatives.filter((c) => !FILTERED_PARTNERS_ID.includes(c.id))
      context.commit('SET_PARTNERS', filteredCooperatives)
    }
  },
}
export const modules = {
  salesModule,
  productModule,
  farmerModule,
  dateModule,
  filterModule,
  salesV3Module,
  exportModule,
}

const store = new Vuex.Store({
  actions,
  getters,
  mutations,
  state,
  modules,
})

export const getStore = () => store

export default store
