import SalesTableUtils from '../CSalesTable/SalesTable.utils';
var firstLevelHeaders = [
    {
        headerName: 'Nom',
        field: 'subkind_label',
        sortable: true,
    },
    {
        headerName: 'Gamme',
        field: 'kind_label',
    },
    {
        headerName: 'Nombre de Ventes',
        field: 'nb_of_orders',
        type: 'rightAligned',
        cellRenderer: 'pTableValueEvolution',
        filter: false,
        comparator: SalesTableUtils.sortEvolutionValue,
    },
    {
        headerName: "Chiffre d'affaires (H.T.)",
        field: 'revenue',
        type: 'rightAligned',
        cellRenderer: 'pTableValueEvolution',
        filter: false,
        valueFormatter: SalesTableUtils.formatCA,
        comparator: SalesTableUtils.sortEvolutionValue,
    },
];
var agGridConfig = {
    defaultColDef: { flex: 1, suppressMovable: true, sortable: true, filter: true },
    columnDefs: firstLevelHeaders,
    onRowGroupOpened: function () { },
};
export default agGridConfig;
