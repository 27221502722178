import SalesTableUtils, { HEADER, HEADER_PRODUIT, HEADER_SOUS_CATEGORIE } from './SalesTable.utils';
import { useAnalyticService } from '@/plugins/aladinGtm';
export var KIND_COL_DEFS = [
    SalesTableUtils.getHeader(HEADER.GAMME),
    SalesTableUtils.getHeader(HEADER.VENTE),
    SalesTableUtils.getHeader(HEADER.PARTMARCHE),
    SalesTableUtils.getHeader(HEADER.CA),
];
export var SUBKIND_COL_DEFS = [
    SalesTableUtils.getSecondLevelHeader(HEADER_SOUS_CATEGORIE.NOM),
    SalesTableUtils.getSecondLevelHeader(HEADER_SOUS_CATEGORIE.NOMBREVENTE),
    SalesTableUtils.getSecondLevelHeader(HEADER_SOUS_CATEGORIE.PARTMARCHE),
    SalesTableUtils.getSecondLevelHeader(HEADER_SOUS_CATEGORIE.CA),
];
export var PRODUCT_COL_DEFS = [
    SalesTableUtils.getLastLevelHeader(HEADER_PRODUIT.NOM),
    SalesTableUtils.getLastLevelHeader(HEADER_PRODUIT.NOMBREVENTE),
    SalesTableUtils.getLastLevelHeader(HEADER_PRODUIT.CA),
];
function onRowGroupOpened(params) {
    var analyticService = useAnalyticService();
    if (params.expanded === true) {
        analyticService.trackEvent('page_sales_partner_click_open_kind', { group_name: params.node.data.name });
        analyticService.removeFromDataLayer('group_name');
    }
    else if (params.expanded === false) {
        analyticService.trackEvent('page_sales_partner_click_close_kind', { group_name: params.node.data.name });
        analyticService.removeFromDataLayer('group_name');
    }
}
export var getAgGridConfig = function (kindColDefs, subkindColDefs, productColDefs) {
    if (kindColDefs === void 0) {
        kindColDefs = KIND_COL_DEFS;
    }
    if (subkindColDefs === void 0) {
        subkindColDefs = SUBKIND_COL_DEFS;
    }
    if (productColDefs === void 0) {
        productColDefs = PRODUCT_COL_DEFS;
    }
    return {
        defaultColDef: { flex: 1, headerClass: 'my_header_class', suppressMovable: true, sortable: true, filter: true },
        masterDetail: true,
        columnDefs: kindColDefs,
        enableCellTextSelection: true,
        enableBrowserTooltips: true,
        detailCellRendererParams: SalesTableUtils.getSubkindLevelRenderer(subkindColDefs, SalesTableUtils.getProductLevelRenderer(productColDefs)),
        onRowGroupOpened: onRowGroupOpened,
    };
};
