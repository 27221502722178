import TrackingEventService from './analytics.service';
// eslint-disable-next-line symbol-description
var trackingEventService;
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function provideAnalyticService(gtmPlugin) {
    trackingEventService = new TrackingEventService(gtmPlugin);
}
export function useAnalyticService() {
    if (!trackingEventService) {
        console.error('trackingEventService is not initialized');
        // EN ATTENTE DU PATCH
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return { trackEvent: function () { }, removeFromDataLayer: function () { } };
    }
    return trackingEventService;
}
