import { defineComponent, computed } from 'vue';
import { useFeatureFlipping } from '@/composables/useFeatureFlipping';
import { useStore } from '@/store/useStore';
import CDatePicker from '@/components/CDatepicker/CDatePicker.vue';
import PCard from '@/presenters/PCard/PCard.vue';
import CFunnel from '@/components/CFunnel/CFunnel.vue';
import CFilter from '@/components/CFilter/CFilter.vue';
import CTopProducts from '@/components/CTopProducts/CTopProducts.vue';
import CTopSubkinds from '@/components/CTopSubkinds/CTopSubkinds.vue';
import CUsersSummary from '@/components/CUsersSummary/CUsersSummary.vue';
import CProductActivityGraph from '@/components/CProductActivityGraph/CProductActivityGraph.vue';
import CFarmersActivityByRegion from '@/components/CFarmersActivityByRegion/CFarmersActivityByRegion.vue';
import CSalesByKindBehavior from '@/components/CSalesByKindBehavior/CSalesByKindBehavior.vue';
import CFarmersPieCharts from '@/components/CFarmersPieCharts/CFarmersPieCharts.vue';
import CFarmersActivityByDistributor from '@/components/CFarmersActivityByDistributor/CFarmersActivityByDistributor.vue';
import CRankingsVsSuppliers from '@/components/CRankingsVsSuppliers/CRankingsVsSuppliers.vue';
export default defineComponent({
    name: 'VFarmer',
    components: {
        CDatePicker: CDatePicker,
        CFilter: CFilter,
        PCard: PCard,
        CFunnel: CFunnel,
        CTopProducts: CTopProducts,
        CTopSubkinds: CTopSubkinds,
        CUsersSummary: CUsersSummary,
        CProductActivityGraph: CProductActivityGraph,
        CFarmersActivityByRegion: CFarmersActivityByRegion,
        CSalesByKindBehavior: CSalesByKindBehavior,
        CFarmersPieCharts: CFarmersPieCharts,
        CFarmersActivityByDistributor: CFarmersActivityByDistributor,
        CRankingsVsSuppliers: CRankingsVsSuppliers,
    },
    setup: function () {
        var store = useStore();
        var features = computed(function () { return store.getters.user.features; });
        function checkFeaturesAccess(featureName) {
            return features.value.includes(featureName);
        }
        return {
            checkFeaturesAccess: checkFeaturesAccess,
            isPieChartsDisplayed: computed(function () { return useFeatureFlipping().isPieChartsDisplayed.value; }),
            isActivityByDistributorsDisplayed: computed(function () { return useFeatureFlipping().farmersActivityByDistributors.value; }),
            isRankingByKindDisplayed: computed(function () { return useFeatureFlipping().isRankingByKindDisplayed.value; }),
        };
    },
});
