<template>
  <div v-if="this.params.value || this.params.valueFormatted" class="vertical-value">
    <div class="vertical-value__value">{{ value }} {{ unit }}</div>
    <div class="vertical-value__evolution-ratio" :class="applyColor">{{ displayedRatio }}</div>
  </div>
</template>

<script>
export default {
  name: 'PTableVerticalValueEvolution',
  props: {},
  data() {
    return {
      value: 0,
      displayedRatio: '',
      evolution_ratio: 0,
    }
  },
  beforeMount() {
    this.value = (this.params && this.params.valueFormatted) || (this.params && this.params.value && this.params.value.value)
    this.evolution_ratio = this.params && this.params.value && this.params.value.evolution_ratio
    this.evolution_unit = this.params && this.params.value && this.params.value.evolution_unit
    this.unit = (this.params && this.params.value && this.params.value.unit) || ''
    this.displayedRatio =
      this.evolution_ratio === null
        ? `- ${this.evolution_unit || '%'}`
        : `${this.evolution_ratio > 0 ? '+' : ''} ${this.evolution_ratio} ${this.evolution_unit}`
  },
  computed: {
    applyColor() {
      return {
        red: this.evolution_ratio < 0,
        green: this.evolution_ratio > 0,
        grey: this.evolution_ratio === 0 || this.evolution_ratio === null,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.vertical-value {
  display: flex;
  gap: $spacing-1;
  justify-content: flex-end;
  flex-direction: column;

  &__value {
    height: 2rem;
  }

  &__evolution-ratio {
    font-size: $font-size-xs;
    font-weight: 400;
    height: 3.5rem;
  }
}

.red {
  color: red;
}

.green {
  color: green;
}

.grey {
  color: grey;
}
</style>
