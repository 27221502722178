var CProductCatalogUtils = /** @class */ (function () {
    function CProductCatalogUtils() {
    }
    CProductCatalogUtils.getPartnerNames = function (listOfIds, partnerList) {
        return listOfIds.map(function (id) {
            var partner = partnerList.find(function (p) { return p.id === Number(id); });
            if (!partner)
                return null;
            return partner.name;
        });
    };
    CProductCatalogUtils.getCatalogWithPartnerNames = function (catalog, partnerList) {
        var _this = this;
        var result = JSON.parse(JSON.stringify(catalog));
        result.forEach(function (kind) {
            // eslint-disable-next-line
            kind.distributors = _this.getPartnerNames(kind.distributors, partnerList);
            kind.categories.forEach(function (subkind) {
                // eslint-disable-next-line
                subkind.distributors = _this.getPartnerNames(subkind.distributors, partnerList);
                subkind.products.forEach(function (product) {
                    // eslint-disable-next-line
                    product.distributors = _this.getPartnerNames(product.distributors, partnerList);
                });
            });
        });
        return result;
    };
    return CProductCatalogUtils;
}());
export default CProductCatalogUtils;
