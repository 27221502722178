/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
import Vue from 'vue'
import Vuex from 'vuex'
import { getColor, getSymbol } from './category-symbol-color.utils'
import { reportingApiService } from '@/services'
// eslint-disable-next-line import/no-cycle

Vue.use(Vuex)

export const mutations = {
  SET_SALES_BY_CATEGORY(state, sales) {
    state.salesByCategory = sales
  },
  SET_SALES_BY_KIND(state, sales) {
    state.salesByKind = sales
  },
  SET_SALES_BY_PARTNER(state, sales) {
    state.salesByPartners = sales
  },
  SET_AGGREGATED_SALES_BY_CATEGORY(state, sales) {
    state.aggregatedSalesByCategory = sales
  },
}

export const getters = {
  salesByCategory(state) {
    return state.salesByCategory
  },
  salesByKind(state) {
    return state.salesByKind
  },
  salesByPartners(state) {
    return state.salesByPartners
  },
  aggregatedSalesByCategory(state) {
    return state.aggregatedSalesByCategory
  },
}

export const actions = {
  async fetchSalesByCategory(context, { isAuthenticated, portalId, startDate = '', endDate = '' }) {
    if (isAuthenticated) {
      const sales = await reportingApiService.getSalesByCategory(portalId, startDate, endDate)
      context.commit('SET_SALES_BY_CATEGORY', sales)
    }
  },
  async fetchSalesByKind(context, { isAuthenticated, portalId, startDate = '', endDate = '', filters = {} }) {
    if (isAuthenticated) {
      const sales = await reportingApiService.getSalesByKind(portalId, startDate, endDate, filters)
      context.commit('SET_SALES_BY_KIND', sales)
    }
  },
  async fetchSalesByPartners(context, { isAuthenticated, portalId, startDate = '', endDate = '' }) {
    if (isAuthenticated) {
      const sales = await reportingApiService.getSalesByPartners(portalId, startDate, endDate)
      context.commit('SET_SALES_BY_PARTNER', sales)
    }
  },
  async fetchAggregatedSalesByCategory(context, { isAuthenticated, portalId, startDate = '', endDate = '' }) {
    if (isAuthenticated) {
      const sales = await reportingApiService.getAggregatedSalesByCategory(portalId, startDate, endDate)
      sales.categories = sales.categories.map((c) => {
        const catWithSymbol = { ...c }
        catWithSymbol.color = getColor(c.name)
        catWithSymbol.symbol = getSymbol(c.name)

        return catWithSymbol
      })
      context.commit('SET_AGGREGATED_SALES_BY_CATEGORY', sales)
    }
  },
}

const moduleSales = {
  state: {
    salesByCategory: [],
    salesByKind: [],
    salesByPartners: [],
    aggregatedSalesByCategory: {},
  },
  actions,
  mutations,
  getters,
}

export default moduleSales
