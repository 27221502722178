var COLORS = [
    '#003ef4',
    '#001f7a',
    '#c50040',
    '#000438',
    '#384740',
    '#97b1fa',
    '#00188d',
    '#d7fbab',
    '#6ddb2e',
    '#227f0e',
    '#feed9e',
    '#ff8c11',
    '#b75208',
    '#fdc5b1',
    '#ed3d3d',
    '#891332',
    '#7d7e99',
    '#b1cb21',
    '#6eaf90',
    '#e88399',
    '#efc400',
];
export default COLORS;
