import { defineComponent, computed } from 'vue';
import DateRangePicker from 'vue2-daterange-picker';
import { useAnalyticService } from '@/plugins/aladinGtm';
import { useStore } from '@/store/useStore';
export default defineComponent({
    name: 'CDatePicker',
    components: {
        DatePicker: DateRangePicker,
    },
    setup: function () {
        var store = useStore();
        var analyticService = useAnalyticService();
        var dateRange = computed(function () { return store.getters.dateRange; });
        var today = new Date();
        var locale = {
            format: 'dd/mm/yyyy',
            separator: ' au ',
            daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
            monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
            firstDay: 1,
        };
        var currentSeasonStartDate = today.getMonth() >= 6 ? new Date(today.getFullYear(), 6, 1) : new Date(today.getFullYear() - 1, 6, 1);
        var currentSeason = {
            startDate: currentSeasonStartDate,
            endDate: new Date(currentSeasonStartDate.getFullYear() + 1, 5, 30),
        };
        var previousSeason = {
            startDate: new Date(currentSeason.startDate.getFullYear() - 1, 6, 1),
            endDate: new Date(currentSeason.endDate.getFullYear() - 1, 5, 30),
        };
        var currentYear = {
            startDate: new Date(today.getFullYear(), 0, 1),
            endDate: new Date(today.getFullYear(), 11, 31),
        };
        var previousYear = {
            startDate: new Date(today.getFullYear() - 1, 0, 1),
            endDate: new Date(today.getFullYear() - 1, 11, 31),
        };
        var rangeSelection = {
            'Cette Campagne': [currentSeason.startDate, currentSeason.endDate],
            'Campagne Précédente': [previousSeason.startDate, previousSeason.endDate],
            'Cette Année': [currentYear.startDate, currentYear.endDate],
            'Année Précédente': [previousYear.startDate, previousYear.endDate],
        };
        function updateDate(_a) {
            var startDate = _a.startDate, endDate = _a.endDate;
            store.dispatch('changeDate', { start: startDate, end: endDate });
            analyticService.trackEvent('global_header_update_filter_date', { start_date: startDate.getTime(), end_date: endDate.getTime() });
            analyticService.removeFromDataLayer('start_date');
            analyticService.removeFromDataLayer('end_date');
        }
        function toggle(isOpen) {
            if (isOpen) {
                analyticService.trackEvent('global_header_click_filter_date', {
                    start_date: dateRange.value.startDate.getTime(),
                    end_date: dateRange.value.endDate.getTime(),
                });
                analyticService.removeFromDataLayer('start_date');
                analyticService.removeFromDataLayer('end_date');
            }
        }
        return {
            dateRange: dateRange,
            rangeSelection: rangeSelection,
            today: today,
            locale: locale,
            updateDate: updateDate,
            toggle: toggle,
        };
    },
});
