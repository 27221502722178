import { defineComponent } from 'vue';
import { getStore } from './store/store';
import { getRouter } from './services/router/router';
import { provideRouter } from '@/services/router/useRouter';
import { provideStore } from '@/store/useStore';
export default defineComponent({
    name: 'App',
    setup: function () {
        var store = getStore();
        var router = getRouter();
        provideStore(store);
        provideRouter(router);
    },
});
