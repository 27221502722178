import CatalogTableUtils, { HEADER, HEADER_PRODUIT, HEADER_SOUS_CATEGORIE } from '@/components/CProductCatalog/CatalogTable.utils';
import { useAnalyticService } from '@/plugins/aladinGtm';
var firstLevelHeaders = [
    CatalogTableUtils.getHeader(HEADER.GAMME),
    CatalogTableUtils.getHeader(HEADER.PRODUITREF),
    CatalogTableUtils.getHeader(HEADER.PRODUITSVENTE),
    CatalogTableUtils.getHeader(HEADER.DISTRIBUTEURS),
];
var secondLevelHeaders = [
    CatalogTableUtils.getSecondLevelHeader(HEADER_SOUS_CATEGORIE.GAMME),
    CatalogTableUtils.getSecondLevelHeader(HEADER_SOUS_CATEGORIE.PRODUITREF),
    CatalogTableUtils.getSecondLevelHeader(HEADER_SOUS_CATEGORIE.PRODUITSVENTE),
    CatalogTableUtils.getSecondLevelHeader(HEADER_SOUS_CATEGORIE.DISTRIBUTEURS),
];
var lastLevelCellRenderer = CatalogTableUtils.getLastLevelRenderer([
    CatalogTableUtils.getLastLevelHeader(HEADER_PRODUIT.PRODUIT),
    CatalogTableUtils.getLastLevelHeader(HEADER_PRODUIT.PHOTO),
    CatalogTableUtils.getLastLevelHeader(HEADER_PRODUIT.NOTES),
    CatalogTableUtils.getLastLevelHeader(HEADER_PRODUIT.DISTRIBUTEURS),
    CatalogTableUtils.getLastLevelHeader(HEADER_PRODUIT.PRIXVENTE),
]);
function onRowGroupOpened(params) {
    var analyticService = useAnalyticService();
    if (params.expanded === true) {
        analyticService.trackEvent('page_catalog_click_open_kind', { group_name: params.node.data.name });
        analyticService.removeFromDataLayer('group_name');
    }
    else if (params.expanded === false) {
        analyticService.trackEvent('page_catalog_click_close_kind', { group_name: params.node.data.name });
        analyticService.removeFromDataLayer('group_name');
    }
}
var agGridConfig = {
    defaultColDef: { flex: 1, headerClass: 'my_header_class', suppressMovable: true, sortable: true, filter: true },
    masterDetail: 'true',
    columnDefs: firstLevelHeaders,
    detailCellRendererParams: CatalogTableUtils.getSecondLevelRenderer(secondLevelHeaders, lastLevelCellRenderer),
    onRowGroupOpened: onRowGroupOpened,
};
export default agGridConfig;
