import { GtmSupport as GtmPlugin, loadScript } from '@gtm-support/core';
import AnalyticsApiService from './analyticsApi.service';
var aladinGtmPlugin = {
    install: function (Vue, aladinGtmOptions) {
        var gtmOptions = aladinGtmOptions.gtmOptions, aladinAnalyticsOptions = aladinGtmOptions.aladinAnalyticsOptions;
        if (!gtmOptions) {
            throw new Error('Options is missing');
        }
        var gtmPlugin = new GtmPlugin(gtmOptions);
        if (gtmPlugin.options.enabled && gtmPlugin.options.loadScript) {
            if (typeof gtmOptions.id === 'string') {
                loadScript(gtmOptions.id, gtmOptions);
            }
        }
        if (aladinAnalyticsOptions) {
            var analyticsApiService = new AnalyticsApiService(aladinAnalyticsOptions);
            window.sendEventToAnalyticsApi = analyticsApiService.sendEventToAnalyticsApi.bind(analyticsApiService);
        }
        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$gtmPlugin = gtmPlugin;
    },
};
export default aladinGtmPlugin;
