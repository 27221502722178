import { render, staticRenderFns } from "./VMainLayout.vue?vue&type=template&id=9137d72e"
import script from "./VMainLayout.vue?vue&type=script&lang=js"
export * from "./VMainLayout.vue?vue&type=script&lang=js"
import style0 from "./VMainLayout.vue?vue&type=style&index=0&id=9137d72e&prod&lang=scss&scope=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports