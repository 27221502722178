const install = (Vue, opts) => {
  if (!opts || !opts.clientId) return

  window.axeptioSettings = {
    clientId: opts.clientId,
    cookiesVersion: opts.base,
    userCookiesDomain: opts.domain,
    jsonCookieName: 'invivo_axeptio_cookies',
    authorizedVendorsCookieName: 'invivo_axeptio_authorized_vendors',
    userCookiesDuration: 182, // 6 mois
  }

  const firstScript = document.getElementsByTagName('script')[0]
  const script = document.createElement('script')
  script.async = true
  script.src = '//static.axept.io/sdk.js'
  // eslint-disable-next-line no-unused-expressions
  if (firstScript.parentNode) {
    firstScript.parentNode.insertBefore(script, firstScript)
  }

  // eslint-disable-next-line no-multi-assign
  Vue.prototype.$axeptioPluginInstalled = Vue.$axeptioPluginInstalled = true
}

export default { install }
