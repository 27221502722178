import { computed, defineComponent } from 'vue';
import CDatePicker from '@/components/CDatepicker/CDatePicker.vue';
import PCard from '@/presenters/PCard/PCard.vue';
import CCatalogSummary from '@/components/CCatalogSummary/CCatalogSummary.vue';
import CSalesSummary from '@/components/CSalesSummary/CSalesSummary.vue';
import CSalesRevenueByKindByMonth from '@/components/CSalesRevenueByKindByMonth/CSalesRevenueByKindByMonth.vue';
import CUsersSummary from '@/components/CUsersSummary/CUsersSummary.vue';
import CProductActivityGraph from '@/components/CProductActivityGraph/CProductActivityGraph.vue';
import CFilter from '@/components/CFilter/CFilter.vue';
import { useStore } from '@/store/useStore';
export default defineComponent({
    name: 'VHome',
    components: {
        CDatePicker: CDatePicker,
        CFilter: CFilter,
        CCatalogSummary: CCatalogSummary,
        CSalesSummary: CSalesSummary,
        CSalesRevenueByKindByMonth: CSalesRevenueByKindByMonth,
        CUsersSummary: CUsersSummary,
        CProductActivityGraph: CProductActivityGraph,
        PCard: PCard,
    },
    setup: function () {
        var store = useStore();
        var features = computed(function () { return store.getters.user.features; });
        function checkFeaturesAccess(featureName) {
            return features.value.includes(featureName);
        }
        return {
            user: computed(function () { return store.getters.user; }),
            checkFeaturesAccess: checkFeaturesAccess,
        };
    },
});
