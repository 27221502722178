import { defineComponent } from 'vue';
export default defineComponent({
    name: 'PVerticalSliderMenuItem',
    props: {
        label: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        hideLabel: {
            type: Boolean,
            default: false,
        },
        isActive: {
            type: Boolean,
            default: false,
        },
        isBeta: {
            type: Boolean,
            default: false,
        },
    },
});
