<template>
  <div>
    <highcharts class="line-chart" :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { Chart } from 'highcharts-vue'

export default defineComponent({
  components: {
    Highcharts: Chart,
  },
  props: {
    seriesData: {
      type: Array,
      default() {
        return []
      },
    },
    categories: {
      type: Array,
      default() {
        return []
      },
    },
    showLegend: {
      type: Boolean,
      default() {
        return false
      },
    },
    showFirstNbSeries: {
      type: Number,
      default() {
        return 0 // No hide
      },
    },
    pointName: {
      type: String,
      default() {
        return ''
      },
    },
  },
  setup({ seriesData, categories, showLegend, showFirstNbSeries, pointName }) {
    function labelFormatter() {
      return `<div class="line-chart__legend__item">
                  <div class="line-chart__legend__item__icon" style="background-color:${this.color};">
                  </div>
                  <span class="line-chart__legend__item__title">${this.name}</span>
                </div>`
    }

    const legendOptions = {
      className: 'line-chart__legend',
      enabled: showLegend,
      layout: 'vertical',
      align: 'left',
      verticalAlign: 'top',
      symbolHeight: 0.001,
      symbolWidth: 0.001,
      symbolRadius: 0.001,
      width: '12%',
      floating: false,
      backgroundColor: '#FFFFFF',
      useHTML: true,
      labelFormatter,
    }

    const chartOptions = ref({
      chart: {
        type: 'line',
        events: {
          load: (event) => {
            if (showFirstNbSeries > 0) {
              for (let i = showFirstNbSeries; i < event.target.series.length; i++) {
                event.target.series[i].hide()
              }
            }
          },
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      xAxis: {
        categories,
      },
      yAxis: {
        title: {
          text: null,
        },
      },
      exporting: {
        enabled: false,
      },
      legend: legendOptions,
      tooltip: {
        padding: 16,
        borderRadius: 8,
        borderWidth: 0,
        useHTML: true,
        backgroundColor: 'white',
        className: 'chart__tooltip',
        headerFormat: '<div class="line-chart__tooltip__title">{point.x}</div>',
        pointFormatter() {
          return `
            <div class="line-chart__tooltip__value">
              ${this.series.name}: <span>${this.y} ${pointName}</span>
            </div>
          `
        },
      },
      series: seriesData,
    })

    return {
      chartOptions,
    }
  },
})
</script>

<style lang="scss">
.line-chart {
  &__tooltip {
    font-family: Heebo, Helvetica, Arial, sans-serif;
    &__title {
      font-weight: bold;
      font-size: $font-size-xs;
      margin-bottom: $spacing-1;
    }
    &__value {
      margin-bottom: $spacing-1;
      color: $color-text-light;
      span {
        font-weight: bold;
      }
    }
  }
  &__legend {
    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: var(--idf-spacing-2);

      &__title {
        font-family: Heebo, Helvetica, Arial, sans-serif;
        font-weight: 100;
        font-size: var(--idf-line-height-xs) !important;
      }

      &__icon {
        border-radius: 1.2rem;
        width: 1.4rem;
        height: 1.4rem;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        color: var(--idf-color-neutral-100);

        &__inactive {
          background-color: #cccccc;
        }
      }
    }
  }

  .highcharts-legend-item-hidden {
    .line-chart__legend__item__icon {
      background-color: #cccccc !important;
    }
  }
}
</style>
