import AdnInput from '@b2ag/design-system/dist/components/AdnInput/AdnInput.vue';
import { defineComponent, ref } from 'vue';
export default defineComponent({
    name: 'CSearchBar',
    components: {
        AdnInput: AdnInput,
    },
    props: {
        salesData: {
            type: Array,
            required: true,
        },
        placeholder: {
            type: String,
            required: false,
        },
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var searchInput = ref();
        function normalize(value) {
            // Replace accents and weird french letters
            return value
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase();
        }
        function searchByCategory(value, salesByCat) {
            return salesByCat.filter(function (cat) {
                if (!normalize(cat.name).includes(value)) {
                    cat.categories_level_1 = cat.categories_level_1.filter(function (subCat) {
                        if (!normalize(subCat.name).includes(value)) {
                            subCat.products = subCat.products.filter(function (p) {
                                if (!normalize(p.name).includes(value) && p.variants) {
                                    p.variants = p.variants.filter(function (v) { return normalize(v.name).includes(value); });
                                    if (p.variants.length > 0) {
                                        p.foundInSearch = true;
                                    }
                                    return p.variants.length > 0;
                                }
                                if (normalize(p.name).includes(value)) {
                                    p.foundInSearch = true;
                                    return true;
                                }
                                return false;
                            });
                            if (subCat.products.length > 0) {
                                subCat.foundInSearch = true;
                            }
                        }
                        return subCat.products.length > 0;
                    });
                    if (cat.categories_level_1.length > 0) {
                        cat.foundInSearch = true;
                    }
                }
                return cat.categories_level_1.length > 0;
            });
        }
        function searchByPartner(value, salesByPartner) {
            return salesByPartner.filter(function (p) {
                if (!normalize(p.partner.name).includes(value)) {
                    p.sales = searchByCategory(value, p.sales);
                }
                return p.sales.length > 0;
            });
        }
        function search(value) {
            var data = JSON.parse(JSON.stringify(props.salesData));
            var result = data;
            if (value !== '') {
                var normalizedValue = normalize(value).toLowerCase().trim();
                if (data[0].partner) {
                    result = searchByPartner(normalizedValue, data);
                }
                else {
                    result = searchByCategory(normalizedValue, data);
                }
            }
            emit('onSearch', result);
        }
        var timer;
        function onSearch(value) {
            clearTimeout(timer);
            timer = setTimeout(function () {
                search(value);
            }, 500);
        }
        function clearSearch() {
            searchInput.value.onIconClearClick();
        }
        return {
            onSearch: onSearch,
            searchInput: searchInput,
            clearSearch: clearSearch,
            search: search,
        };
    },
});
