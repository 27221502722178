<template>
  <div v-if="this.params.value || this.params.valueFormatted" class="value">
    <div class="value__value">{{ value }} {{ unit }}</div>
    <div class="value__evolution-ratio" v-if="evolution_ratio !== null" :class="applyColor">
      {{ evolution_ratio > 0 ? '+' : '' }}{{ evolution_ratio }} {{ evolution_unit }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PTableValueEvolution',
  props: {},
  data() {
    return {
      value: 0,
      evolution_ratio: 0,
    }
  },
  beforeMount() {
    this.value = (this.params && this.params.valueFormatted) || (this.params && this.params.value && this.params.value.value)
    this.evolution_ratio = this.params && this.params.value && this.params.value.evolution_ratio
    this.evolution_unit = this.params && this.params.value && this.params.value.evolution_unit
    this.unit = (this.params && this.params.value && this.params.value.unit) || ''
  },
  computed: {
    applyColor() {
      return {
        red: this.evolution_ratio < 0,
        green: this.evolution_ratio > 0,
        grey: this.evolution_ratio === 0,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.value {
  display: flex;
  gap: $spacing-1;
  justify-content: flex-end;

  &__evolution-ratio {
    font-size: $font-size-xs;
    font-weight: 400;
  }
}

.red {
  color: red;
}

.green {
  color: green;
}

.grey {
  color: grey;
}
</style>
