<template>
  <span :class="isPhotoUrl ? 'adn-icon adn-icon--status-check' : 'adn-icon adn-icon--status-unavailable'"></span>
</template>

<script>
export default {
  name: 'PCheckPhoto',
  data() {
    return {
      isPhotoUrl: '',
    }
  },
  beforeMount() {
    this.isPhotoUrl = !!this.params.value
  },
}
</script>

<style lang="scss"></style>
