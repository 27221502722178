import { useAnalyticService } from '@/plugins/aladinGtm';
import { formatService } from '@/services';
/* eslint-disable-next-line */
export var HEADER;
(function (HEADER) {
    HEADER["GAMME"] = "gamme";
    HEADER["VENTE"] = "vente";
    HEADER["CA"] = "ca";
    HEADER["SURFACE"] = "surface";
    HEADER["PARTMARCHE"] = "partmarche";
})(HEADER || (HEADER = {}));
/* eslint-disable-next-line */
export var HEADER_SOUS_CATEGORIE;
(function (HEADER_SOUS_CATEGORIE) {
    HEADER_SOUS_CATEGORIE["NOM"] = "SC_name";
    HEADER_SOUS_CATEGORIE["NOMBREVENTE"] = "SC_nbvente";
    HEADER_SOUS_CATEGORIE["CA"] = "SC_ca";
    HEADER_SOUS_CATEGORIE["SURFACE"] = "SC_surface";
    HEADER_SOUS_CATEGORIE["PARTMARCHE"] = "SC_partmarche";
})(HEADER_SOUS_CATEGORIE || (HEADER_SOUS_CATEGORIE = {}));
/* eslint-disable-next-line */
export var HEADER_PRODUIT;
(function (HEADER_PRODUIT) {
    HEADER_PRODUIT["NOM"] = "P_nom";
    HEADER_PRODUIT["NOMBREVENTE"] = "P_nbvente";
    HEADER_PRODUIT["CA"] = "P_ca";
    HEADER_PRODUIT["SURFACE"] = "P_surface";
})(HEADER_PRODUIT || (HEADER_PRODUIT = {}));
var SalesTableUtils = /** @class */ (function () {
    function SalesTableUtils() {
    }
    SalesTableUtils.getHeader = function (header) {
        return SalesTableUtils.headerMap.get(header);
    };
    SalesTableUtils.getSecondLevelHeader = function (header) {
        return SalesTableUtils.headerMap.get(header);
    };
    SalesTableUtils.getLastLevelHeader = function (header) {
        return SalesTableUtils.headerMap.get(header);
    };
    SalesTableUtils.getProductLevelRenderer = function (columnDefs) {
        return function (masterGridParams) {
            return {
                detailGridOptions: {
                    context: {
                        masterGrid: {
                            node: masterGridParams.node.parent,
                            data: masterGridParams.data,
                        },
                    },
                    isRowMaster: function (p) { return p.variants; },
                    masterDetail: true,
                    enableCellTextSelection: true,
                    rowHeight: 70,
                    enableBrowserTooltips: true,
                    detailCellRendererParams: SalesTableUtils.getVariantsLevelRenderer(columnDefs),
                    rowClassRules: {
                        'my-row-odd': SalesTableUtils.colorRowOdd,
                    },
                    onGridReady: function (event) {
                        event.api.sizeColumnsToFit();
                        for (var i = 0; i < event.api.getDisplayedRowCount(); i++) {
                            var row = event.api.getDisplayedRowAtIndex(i);
                            row.setExpanded(row.data.foundInSearch);
                        }
                    },
                    onRowGroupOpened: function (params) {
                        var analyticService = useAnalyticService();
                        if (params.expanded === true) {
                            analyticService.trackEvent('page_sales_partner_click_open_product', { group_name: params.node.data.name });
                            analyticService.removeFromDataLayer('group_name');
                        }
                        else if (params.expanded === false) {
                            analyticService.trackEvent('page_sales_partner_click_close_product', { group_name: params.node.data.name });
                            analyticService.removeFromDataLayer('group_name');
                        }
                    },
                    detailRowAutoHeight: true,
                    columnDefs: columnDefs,
                    rowClass: 'row_class_level_2',
                    suppressCellFocus: true,
                    headerHeight: 32,
                    defaultColDef: { flex: 1, sortable: true, filter: true, type: 'rightAligned', suppressMovable: true },
                },
                getDetailRowData: function (params) {
                    params.successCallback(params.data.products);
                },
            };
        };
    };
    SalesTableUtils.getVariantsLevelRenderer = function (columnDefs) {
        return function (masterGridParams) {
            return {
                detailGridOptions: {
                    context: {
                        masterGrid: {
                            node: masterGridParams.node.parent,
                            data: masterGridParams.data,
                        },
                    },
                    onGridReady: function (event) {
                        event.api.sizeColumnsToFit();
                    },
                    rowClassRules: {
                        'my-row-odd': SalesTableUtils.colorRowSameAsParent,
                    },
                    columnDefs: columnDefs,
                    enableCellTextSelection: true,
                    enableBrowserTooltips: true,
                    rowHeight: 70,
                    rowClass: 'row_class_level_3',
                    suppressCellFocus: true,
                    headerHeight: 0,
                    defaultColDef: { flex: 1, sortable: true, filter: true, type: 'rightAligned', suppressMovable: true },
                },
                getDetailRowData: function (params) {
                    params.successCallback(params.data.variants);
                },
            };
        };
    };
    SalesTableUtils.getSubkindLevelRenderer = function (columnDefs, detailCellRendererParams) {
        return {
            detailGridOptions: {
                detailRowAutoHeight: true,
                enableCellTextSelection: true,
                columnDefs: columnDefs,
                defaultColDef: { flex: 1, sortable: true, filter: true },
                rowClass: 'row_class_level_1',
                masterDetail: true,
                headerHeight: 0,
                rowHeight: 70,
                suppressRowHoverHighlight: true,
                enableBrowserTooltips: true,
                suppressCellFocus: true,
                rowClassRules: {
                    'my-row-odd': SalesTableUtils.colorRowOdd,
                },
                detailCellRendererParams: detailCellRendererParams,
                onGridReady: function (event) {
                    for (var i = 0; i < event.api.getDisplayedRowCount(); i++) {
                        var row = event.api.getDisplayedRowAtIndex(i);
                        row.setExpanded(row.data.foundInSearch);
                    }
                },
                onRowGroupOpened: function (params) {
                    var analyticService = useAnalyticService();
                    if (params.expanded === true) {
                        analyticService.trackEvent('page_sales_partner_click_open_subkind', { group_name: params.node.data.name });
                        analyticService.removeFromDataLayer('group_name');
                    }
                    else if (params.expanded === false) {
                        analyticService.trackEvent('page_sales_partner_click_close_subkind', { group_name: params.node.data.name });
                        analyticService.removeFromDataLayer('group_name');
                    }
                },
            },
            getDetailRowData: function (params) {
                params.successCallback(params.data.subkind_sales);
            },
        };
    };
    SalesTableUtils.formatCA = function (params) {
        var _a;
        return formatService.formatCA((_a = params.value) === null || _a === void 0 ? void 0 : _a.value).replace('.', ',');
    };
    SalesTableUtils.formatMarketShare = function (params) {
        var _a;
        return formatService.formatMarketShare((_a = params.value) === null || _a === void 0 ? void 0 : _a.value);
    };
    SalesTableUtils.colorRowSameAsParent = function (params) {
        return params.context.masterGrid.node.childIndex % 2 !== 0;
    };
    SalesTableUtils.colorRowOdd = function (params) {
        return params.rowIndex % 2 !== 0;
    };
    SalesTableUtils.sortEvolutionValue = function (valueA, valueB) {
        if (!(valueA === null || valueA === void 0 ? void 0 : valueA.value))
            return 1;
        if (!(valueB === null || valueB === void 0 ? void 0 : valueB.value))
            return -1;
        return (valueB === null || valueB === void 0 ? void 0 : valueB.value) - (valueA === null || valueA === void 0 ? void 0 : valueA.value);
    };
    SalesTableUtils.headerMap = new Map()
        .set(HEADER.GAMME, {
        headerName: 'Gamme',
        field: 'name',
        cellRenderer: 'agGroupCellRenderer',
        minWidth: 200,
        tooltipField: 'name',
    })
        .set(HEADER.VENTE, {
        headerName: 'Ventes',
        field: 'nb_sales',
        type: 'rightAligned',
        filter: false,
        cellRenderer: 'pTableVerticalValueEvolution',
        comparator: SalesTableUtils.sortEvolutionValue,
    })
        .set(HEADER.CA, {
        headerName: 'Chiffre d’affaires (H.T.)',
        field: 'revenue',
        type: 'rightAligned',
        filter: false,
        cellRenderer: 'pTableVerticalValueEvolution',
        minWidth: 200,
        valueFormatter: SalesTableUtils.formatCA,
        comparator: SalesTableUtils.sortEvolutionValue,
    })
        .set(HEADER.SURFACE, {
        headerName: 'Surface couverte',
        field: 'covered_area',
        filter: false,
        type: 'rightAligned',
        cellRenderer: 'pTableVerticalValueEvolution',
        comparator: SalesTableUtils.sortEvolutionValue,
    })
        .set(HEADER.PARTMARCHE, {
        headerName: 'Part de marché',
        field: 'market_share',
        type: 'rightAligned',
        filter: false,
        cellRenderer: 'pTableVerticalValueEvolution',
        valueFormatter: SalesTableUtils.formatMarketShare,
        comparator: SalesTableUtils.sortEvolutionValue,
    })
        .set(HEADER_SOUS_CATEGORIE.NOM, {
        field: 'name',
        cellRenderer: 'agGroupCellRenderer',
        sortable: true,
        minWidth: 200,
        tooltipField: 'name',
    })
        .set(HEADER_SOUS_CATEGORIE.NOMBREVENTE, {
        field: 'nb_sales',
        type: 'rightAligned',
        filter: false,
        cellRenderer: 'pTableVerticalValueEvolution',
        comparator: SalesTableUtils.sortEvolutionValue,
    })
        .set(HEADER_SOUS_CATEGORIE.CA, {
        field: 'revenue',
        type: 'rightAligned',
        filter: false,
        cellRenderer: 'pTableVerticalValueEvolution',
        minWidth: 200,
        valueFormatter: SalesTableUtils.formatCA,
        comparator: SalesTableUtils.sortEvolutionValue,
    })
        .set(HEADER_SOUS_CATEGORIE.SURFACE, {
        headerName: 'Surface couverte',
        field: 'covered_area',
        filter: false,
        type: 'rightAligned',
        cellRenderer: 'pTableVerticalValueEvolution',
        comparator: SalesTableUtils.sortEvolutionValue,
    })
        .set(HEADER_SOUS_CATEGORIE.PARTMARCHE, {
        field: 'market_share',
        type: 'rightAligned',
        filter: false,
        cellRenderer: 'pTableVerticalValueEvolution',
        valueFormatter: SalesTableUtils.formatMarketShare,
        comparator: SalesTableUtils.sortEvolutionValue,
    })
        .set(HEADER_PRODUIT.NOM, {
        headerName: 'Nom',
        field: 'name',
        headerClass: 'last-level-first-column-header',
        cellClass: 'sales-last-level-first-column-cell',
        cellRenderer: 'agGroupCellRenderer',
        width: 300,
        resizable: false,
        tooltipField: 'name',
    })
        .set(HEADER_PRODUIT.NOMBREVENTE, {
        headerName: 'Ventes',
        field: 'nb_sales',
        filter: false,
        cellRenderer: 'pTableVerticalValueEvolution',
        comparator: SalesTableUtils.sortEvolutionValue,
    })
        .set(HEADER_PRODUIT.CA, {
        headerName: 'Chiffre d’affaires (H.T.)',
        field: 'revenue',
        filter: false,
        cellRenderer: 'pTableVerticalValueEvolution',
        minWidth: 200,
        valueFormatter: SalesTableUtils.formatCA,
        comparator: SalesTableUtils.sortEvolutionValue,
    })
        .set(HEADER_PRODUIT.SURFACE, {
        headerName: 'Surface couverte',
        field: 'covered_area',
        filter: false,
        type: 'rightAligned',
        cellRenderer: 'pTableVerticalValueEvolution',
        comparator: SalesTableUtils.sortEvolutionValue,
    });
    return SalesTableUtils;
}());
export default SalesTableUtils;
