// eslint-disable-next-line import/no-cycle
import Vue from 'vue'
import Router from 'vue-router'
import { useAuthService, useScopesService, VAuthCallback, VSilentRefreshCallback } from '../auth'
import { useAnalyticService } from '../../plugins/aladinGtm'
import VBuyers from '../../views/VBuyers/VBuyers.vue'
import VMainLayout from '../../views/VMainLayout/VMainLayout.vue'
import VNotFound from '../../views/VNotFound/VNotFound.vue'
import VSales from '../../views/VSales/VSales.vue'
import VSalesBySupplier from '../../views/VSalesBySupplier/VSalesBySupplier.vue'
import VSalesByRegion from '../../views/VSalesByRegion/VSalesByRegion.vue'
import VFarmers from '../../views/VFarmers/VFarmers.vue'
import VCatalog from '../../views/VCatalog/VCatalog.vue'
import VHome from '../../views/VHome/VHome.vue'
import VError from '../../views/VError/VError.vue'
import store from '@/store/store'

Vue.use(Router)

const PAGE_TYPE = {
  DEFAULT: 'Default',
  HOME: 'Accueil',
  CATALOG: 'Catalogue',
  SALES: 'Ventes',
  FARMERS: 'Agriculteurs',
  PARTNERS: 'Distributeurs',
  OAUTH: 'OAUTH',
  NOTFOUND: 'NotFound',
  ERROR: 'Erreur',
}

export const PUBLIC_ROUTES = ['oauth-callback']
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: VMainLayout,
      children: [
        {
          path: '',
          name: 'home',
          component: VHome,
          meta: {
            category: PAGE_TYPE.HOME,
            title: 'Accueil',
          },
        },
        {
          path: 'ventes/distributeurs',
          name: 'salesBySupplier',
          component: VSalesBySupplier,
          meta: {
            category: PAGE_TYPE.SALES,
            title: 'Ventes',
          },
        },
        {
          path: '/oauth/silentCallback',
          name: 'auth-silent-callback',
          meta: {
            public: true,
          },
          component: VSilentRefreshCallback,
        },
        {
          path: '/oauth/callback',
          name: 'oauth-callback',
          component: VAuthCallback,
          meta: {
            category: PAGE_TYPE.OAUTH,
          },
        },
        {
          path: 'ventes',
          name: 'sales',
          component: VSales,
          meta: {
            category: PAGE_TYPE.SALES,
            title: 'Ventes',
          },
        },
        {
          path: 'ventes/region',
          name: 'salesByRegion',
          component: VSalesByRegion,
          meta: {
            category: PAGE_TYPE.SALES,
            title: 'Ventes',
          },
        },
        {
          path: 'exploitations-agricoles',
          name: 'agriculturalExploitations',
          component: VFarmers,
          meta: {
            category: PAGE_TYPE.FARMERS,
            title: 'Exploitation Agricoles',
          },
        },
        {
          path: 'catalogue',
          name: 'catalog',
          component: VCatalog,
          meta: {
            category: PAGE_TYPE.CATALOG,
            title: 'Catalogue',
          },
        },
        {
          path: 'acheteurs',
          name: 'buyers',
          component: VBuyers,
          meta: {
            category: PAGE_TYPE.PARTNERS,
            title: 'Distributeurs',
          },
        },
      ],
    },
    {
      path: 'erreur',
      name: 'error',
      component: VError,
      props: true,
      meta: {
        category: PAGE_TYPE.ERROR,
        title: 'Erreur',
      },
    },
    {
      path: '*',
      name: 'not-found',
      component: VNotFound,
      meta: {
        category: PAGE_TYPE.NOTFOUND,
        title: 'Not found',
      },
    },
  ],
})
export async function routerBeforeEach(storeInstance, to, _from, next) {
  if (PUBLIC_ROUTES.includes(to.name)) {
    return next()
  }

  const authService = useAuthService()
  const isAuthenticated = await authService.isAuthenticated()
  const isExpired = await authService.isExpired()

  if (!isAuthenticated || isExpired) {
    await authService.doLogin()
    return false
  }

  const scopesService = useScopesService()
  const userScopes = await authService.getUserScopes()
  try {
    scopesService.validateScopes(userScopes)
  } catch (err) {
    await authService.doLogout()
    return false
  }

  if (!storeInstance.getters.isStoreInitialized) {
    // TODO: reflexion a avoir sur le placement de l'init
    await store.dispatch('init')
    const analyticService = useAnalyticService()
    analyticService.trackEvent('init', {})
  }

  if (!store.getters.user.features.includes('agriculturalExploitations') && to.name === 'agriculturalExploitations') {
    router.push({ name: 'home' })
  }
  return next()
}

function routerAfterEach(storeInstance, to) {
  if (!store.getters.user.features.includes('salesByRegion') && to.name === 'salesByRegion') {
    router.push({ name: 'home' })
  } else {
    const analyticService = useAnalyticService()
    const metaData = {
      page: {
        path: to.path,
        url: window.location.origin + to.path,
        category: to.meta.category,
        env: window.env.ENVIRONMENT_NAME,
        name: to.name,
      },
      user: {
        id: storeInstance.getters.user.id,
        portal_name: storeInstance.getters.user.portal_name,
        features: storeInstance.getters.user.features,
      },
    }

    analyticService.trackEvent('ff_page_view', metaData)
  }
}

router.beforeEach((to, from, next) => routerBeforeEach(store, to, from, next))
router.afterEach((to, from) => routerAfterEach(store, to, from))

export const getRouter = () => router

export default router
