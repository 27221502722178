<template>
  <div>
    <div class="main-container">
      <main>
        <c-menu class="menu" />
        <router-view class="router-view" />
      </main>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import CMenu from '@/components/CMenu/CMenu.vue'

export default defineComponent({
  name: 'VMainLayout',
  components: {
    CMenu,
  },
})
</script>

<style lang="scss" scope>
.main-container {
  background-color: $color-background;
}

.menu {
  display: inline;
  float: left;
  min-height: calc(100vh - 70px - 5rem);
  position: relative;
}

main {
  display: flex !important;
  gap: $spacing-5;
  margin-bottom: 5rem;
}

.router-view {
  flex-grow: 1;
  margin-right: $spacing-5;
  margin-top: $spacing-5;
}
</style>
