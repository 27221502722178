import { formatToTimeZone } from 'date-fns-timezone'

// eslint-disable-next-line
export const getFormattedDate = (dateString) => {
  const date = new Date(dateString)
  return {
    date: formatToTimeZone(date, 'DD/MM/YYYY', { timeZone: 'Europe/Paris' }),
    hour: formatToTimeZone(date, 'hh:mm', { timeZone: 'Europe/Paris' }),
  }
}
