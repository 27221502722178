import { render, staticRenderFns } from "./PCard.vue?vue&type=template&id=34fba417"
import script from "./PCard.vue?vue&type=script&lang=js"
export * from "./PCard.vue?vue&type=script&lang=js"
import style0 from "./PCard.vue?vue&type=style&index=0&id=34fba417&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports