import { defineComponent, ref } from 'vue';
export default defineComponent({
    name: 'PVerticalSlider',
    setup: function (_, _a) {
        var emit = _a.emit;
        var isOpen = ref(false);
        var isFinishedTransition = ref(true);
        var slider = ref();
        function onTransitionEnd() {
            isFinishedTransition.value = true;
            if (isOpen.value) {
                emit('open');
            }
        }
        function onMouseOver() {
            isOpen.value = true;
            isFinishedTransition.value = false;
        }
        function onMouseLeave() {
            isOpen.value = false;
            isFinishedTransition.value = false;
            emit('close');
        }
        return { onMouseOver: onMouseOver, onMouseLeave: onMouseLeave, onTransitionEnd: onTransitionEnd, isFinishedTransition: isFinishedTransition, isOpen: isOpen, slider: slider };
    },
});
