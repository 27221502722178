import Vue from 'vue';
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import Maps from 'highcharts/modules/map';
import HighchartsMore from 'highcharts/highcharts-more';
import SolidGauge from 'highcharts/modules/solid-gauge';
HighchartsMore(Highcharts);
Maps(Highcharts);
SolidGauge(Highcharts);
Vue.use(HighchartsVue);
