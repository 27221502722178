import { defineComponent, ref } from 'vue';
import { Chart } from 'highcharts-vue';
export default defineComponent({
    name: 'PPieChart',
    components: {
        highcharts: Chart,
    },
    props: {
        title: { type: String, required: true },
        data: { type: Array, required: true },
        enableTooltip: { type: Boolean, default: false },
    },
    setup: function (_a) {
        var title = _a.title, data = _a.data, enableTooltip = _a.enableTooltip;
        var pieOptions = ref({
            chart: { type: 'pie', styledMode: false },
            title: { text: title, style: { fontFamily: 'var(--idf-font-family-primary)', fontWeight: 'bold' } },
            plotOptions: {
                pie: {
                    size: 240,
                    dataLabels: {
                        connectorColor: '#000',
                        enabled: true,
                        format: '{point.name}: </br><b>{point.percent} %</b>',
                        padding: 0,
                        style: {
                            fontSize: data.length > 6 ? '12px' : '14px',
                            textOverflow: 'clip',
                            fontWeight: 'normal',
                        },
                    },
                    states: {
                        hover: {
                            enabled: false,
                            brightness: 0,
                        },
                    },
                },
                series: {
                    states: {
                        hover: {
                            enabled: false,
                            halo: {
                                size: 0,
                            },
                        },
                        inactive: {
                            opacity: 1,
                        },
                    },
                },
            },
            tooltip: {
                enabled: enableTooltip,
                pointFormat: '<b>{point.y}</b>',
            },
            series: [{ name: '', data: data }],
        });
        return {
            pieOptions: pieOptions,
        };
    },
});
