<template>
  <div class="big-number">
    <slot></slot>
    <div class="big-number__title">{{ title }}</div>
    <div class="big-number__separator"></div>
    <div class="big-number__content">
      <div class="big-number__icon-wrapper">
        <span class="adn-icon big-number__icon" :class="'adn-icon--' + iconName" aria-hidden="true" />
      </div>
      <div class="big-number__number">{{ number }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PBigNumber',
  props: {
    number: {
      type: [String, Number],
    },
    title: {
      type: String,
    },
    iconName: {
      type: String,
    },
  },
}
</script>

<style lang="scss">
@import '~@b2ag/design-system/dist/aladin.scss';
.big-number {
  width: 205px;
  padding: 20px;
  border-radius: 8px;
  color: $color-white;
  .big-number__number {
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    margin-left: auto;
  }
  .big-number__title {
    font-size: 16px;
    line-height: 22px;
    text-align: start;
  }
  .big-number__separator {
    margin: 10px 0;
    border-bottom: 1px solid $color-white;
  }
  .big-number__icon {
    font-size: 30px;
  }
  .big-number__icon-wrapper {
    display: flex;
    align-items: center;
  }
  .big-number__content {
    display: flex;
  }
}
</style>
