var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [op[0] & 2, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import AdnButton from '@b2ag/design-system/dist/components/AdnButton/AdnButton.vue';
import AdnAnimatedLoading from '@b2ag/design-system/dist/components/AdnAnimatedLoading/AdnAnimatedLoading.vue';
import { defineComponent, onBeforeMount, ref } from 'vue';
import { STATE_INVALID, INVALID_SCOPE, CONTENT_ON_OAUTH_ERROR } from './VAuthCallback.constants';
import { useAuthService } from '../useAuth';
import { useScopesService } from '../useScopes';
import { useRouter } from '@/services/router/useRouter';
function handleOAuthError(_a) {
    var errorDescription = _a.errorDescription;
    switch (errorDescription) {
        case STATE_INVALID:
            return CONTENT_ON_OAUTH_ERROR[STATE_INVALID];
        default:
            return CONTENT_ON_OAUTH_ERROR.default;
    }
}
function handleInvalidScopesError() {
    return CONTENT_ON_OAUTH_ERROR[INVALID_SCOPE];
}
export default defineComponent({
    components: {
        AdnButton: AdnButton,
        AdnAnimatedLoading: AdnAnimatedLoading,
    },
    setup: function () {
        var _this = this;
        var router = useRouter();
        var auth = useAuthService();
        var scopes = useScopesService();
        var error = ref();
        onBeforeMount(function () {
            return __awaiter(_this, void 0, void 0, function () {
                var userScopes, err_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 5, , 6]);
                            return [4 /*yield*/, auth.doLoginCallback()];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, auth.getUserScopes()];
                        case 2:
                            userScopes = _a.sent();
                            return [4 /*yield*/, scopes.validateScopes(userScopes)
                                // Handle authentication success
                            ];
                        case 3:
                            _a.sent();
                            // Handle authentication success
                            return [4 /*yield*/, router.push({ name: 'home' })];
                        case 4:
                            // Handle authentication success
                            _a.sent();
                            return [3 /*break*/, 6];
                        case 5:
                            err_1 = _a.sent();
                            // Handle authentication error
                            if (err_1.message === INVALID_SCOPE) {
                                error.value = handleInvalidScopesError();
                            }
                            else {
                                error.value = handleOAuthError(err_1);
                            }
                            return [3 /*break*/, 6];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        });
        function logout() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, auth.doLogout()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
        return {
            error: error,
            logout: logout,
        };
    },
});
