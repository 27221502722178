/* eslint-disable import/prefer-default-export */
import ReportingApiService from './reportingApi.service'
import OfferApiService from './offerApi.service'
import formatService from './format.service'

window.env = window.env || {}

const reportingApiHost = window.env.REPORTING_API_HOST
const productApiHost = window.env.PRODUCT_API_HOST

const reportingApiService = new ReportingApiService({ reportingApiHost })
const offerApiService = new OfferApiService({ productApiHost })

export { reportingApiService, offerApiService, formatService }
