<template>
  <div class="container buyers">
    <c-last-update-title> Résumé du potentiel d'aladin </c-last-update-title>
    <c-buyers-partners v-if="isDataLoaded"></c-buyers-partners>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import CBuyersPartners from '../../components/CBuyersPartners/CBuyersPartners.vue'
import { useStore } from '@/store/useStore'
import CLastUpdateTitle from '@/components/CLastUpdateTitle/CLastUpdateTitle.vue'

export default defineComponent({
  name: 'VBuyers',
  components: {
    CBuyersPartners,
    CLastUpdateTitle,
  },
  setup() {
    const store = useStore()

    const isDataLoaded = computed(() => store.getters.isStoreInitialized)
    return {
      isDataLoaded,
    }
  },
})
</script>

<style lang="scss">
.buyers {
  margin-bottom: 40px;
}
.buyers__big-number--bg-primary {
  background: $color-main;
}
.buyers__big-number--bg-purple {
  background: $color-purple;
}
.buyers__big-number--bg-green {
  background: $color-secondary;
}
</style>
