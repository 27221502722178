import { defineComponent } from 'vue';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import PTableValueEvolution from '@/presenters/PTableValueEvolution/PTableValueEvolution.vue';
export default defineComponent({
    name: 'POneLvlTable',
    components: {
        AgGridVue: AgGridVue,
        pTableValueEvolution: PTableValueEvolution,
    },
    props: {
        rowData: { type: Array, required: true },
        agGridConfig: { type: Object, required: true },
    },
});
