var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s)
                if (Object.prototype.hasOwnProperty.call(s, p))
                    t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SalesTableUtils from '../CSalesTable/SalesTable.utils';
var SALES_BY_REGION_TABLE_CONFIG = {
    defaultColDef: { flex: 1, suppressMovable: true, sortable: true, filter: true },
    rowSelection: 'single',
    columnDefs: [
        {
            headerName: 'Région',
            field: 'region_name',
            sortable: true,
        },
        {
            headerName: 'Ranking',
            valueGetter: function (params) {
                return __assign(__assign({}, params.data.ranking), { value: "".concat(params.data.ranking.value, " sur ").concat(params.data.total_number_of_suppliers) });
            },
            type: 'rightAligned',
            cellRenderer: 'pTableValueEvolution',
            comparator: SalesTableUtils.sortEvolutionValue,
        },
        {
            headerName: "Chiffre d'affaires (H.T.)",
            field: 'revenue',
            type: 'rightAligned',
            cellRenderer: 'pTableValueEvolution',
            filter: false,
            valueFormatter: SalesTableUtils.formatCA,
            comparator: SalesTableUtils.sortEvolutionValue,
        },
    ],
};
export default SALES_BY_REGION_TABLE_CONFIG;
